import React, { useState, useEffect, useRef } from 'react';
import { X, Clock, Upload, Trash2, Settings, Mic, StopCircle } from 'lucide-react';
import { useWallet } from '@solana/wallet-adapter-react';
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  doc,
  deleteDoc,
  updateDoc,
  getDoc,
  Timestamp
} from 'firebase/firestore';

// We import getStorage, ref, uploadBytes, getDownloadURL
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import { app, db, firebaseManager } from './firebaseConfig'; // your firebase config
// Make sure firebaseConfig.js exports the same 'app' from initializeApp(...).

////////////////////////////////////////
// Inline GPT-4o-mini Vision function //
////////////////////////////////////////
async function evaluateResponseMatch({
  requestText,
  responseText,
  imagesBase64,
  audioBase64
}) {
  const openAIKey = 'sk-proj-EXAMPLEKEY-REPLACE-ME';

  if (!openAIKey) {
    console.warn('No OPENAI_API_KEY found. Returning 100% match.');
    return { score: 100, reasoning: 'No key => default 100%' };
  }

  // We build a system message plus a user message with content blocks
  const messages = [
    {
      role: 'system',
      content: `You are a helpful AI that can analyze text, images, and audio for matching a user request:
"${requestText}"

Output EXACTLY:

SCORE: XX
REASONING: short reason

Where XX is an integer 0-100.`
    }
  ];

  // Build user content
  const userContent = [];

  if (responseText?.trim()) {
    userContent.push({
      type: 'text',
      text: {
        value: `User text response:\n${responseText.trim()}`
      }
    });
  }

  imagesBase64.forEach((imgB64, idx) => {
    userContent.push({
      type: 'image_url',
      image_url: {
        url: imgB64, // base64
        detail: 'auto'
      }
    });
  });

  if (audioBase64) {
    userContent.push({
      type: 'text',
      text: {
        value: `User also provided audio in base64:\n${audioBase64.slice(0, 1000)}... (truncated)`
      }
    });
  }

  messages.push({
    role: 'user',
    content: userContent
  });

  const body = {
    model: 'gpt-4o-mini', // or "gpt-4o"
    messages,
    temperature: 0.0
  };

  try {
    const res = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${openAIKey}`
      },
      body: JSON.stringify(body)
    });
    if (!res.ok) {
      console.error('OpenAI responded with an error:', res.status);
      return { score: 100, reasoning: `Error calling GPT-4o-mini, fallback.` };
    }
    const data = await res.json();
    const raw = (data.choices?.[0]?.message?.content || '').trim();
    const lines = raw.split('\n');

    let scoreLine = lines.find((l) => l.toUpperCase().includes('SCORE:'));
    let reasonLine = lines.find((l) => l.toUpperCase().includes('REASONING:'));

    let scoreVal = 100;
    let reasoningVal = 'No reasoning found.';
    if (scoreLine) {
      const digits = scoreLine.replace(/[^0-9]/g, '');
      const parsed = parseInt(digits, 10);
      if (!isNaN(parsed)) scoreVal = parsed;
    }
    if (reasonLine) {
      reasoningVal = reasonLine.replace(/REASONING:/i, '').trim();
    }

    return { score: scoreVal, reasoning: reasoningVal };
  } catch (err) {
    console.error('Error calling GPT-4o-mini:', err);
    return { score: 100, reasoning: 'Error calling GPT-4o-mini, fallback.' };
  }
}

//////////////////////////////////////
// DETERMINE CORRECT COLLECTION NAME //
//////////////////////////////////////
function getCollections() {
  const currentEnv = firebaseManager.getCurrentEnvironment();
  const currentLedger = firebaseManager.getCurrentLedger();

  let requestsCollectionName = 'requests';
  if (currentEnv === 'development') {
    requestsCollectionName =
      currentLedger === 'live' ? 'requests_dev_live' : 'requests_dev_test';
  } else {
    requestsCollectionName = 'requests_prod_live';
  }
  return { requestsCollectionName };
}

//////////////////////////////////////
// MAIN DetailsPanel
//////////////////////////////////////
export default function DetailsPanel({ request, onClose, userId, userLocation }) {
  const wallet = useWallet();
  const [responses, setResponses] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState(9999999999);

  const { requestsCollectionName } = getCollections();
  // Our Storage instance from the same app
  const storage = getStorage(app);

  // For text
  const [infoText, setInfoText] = useState('');
  const [invalidFeedback, setInvalidFeedback] = useState('');

  // For images (we store local "file" + "previewURL")
  const [imageSlots, setImageSlots] = useState([]);

  // For audio
  const [audioFile, setAudioFile] = useState(null);
  const [audioFilePreview, setAudioFilePreview] = useState(null);

  // Recording
  const [isRecording, setIsRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const mediaRecorderRef = useRef(null);
  const [recordTimer, setRecordTimer] = useState(0);
  const recordIntervalRef = useRef(null);

  const [isEditing, setIsEditing] = useState(false);

  // Load existing responses
  useEffect(() => {
    if (!request?.id) return;
    const q = query(collection(db, requestsCollectionName, request.id, 'responses'), orderBy('createdAt','desc'));
    const unsub = onSnapshot(q, (snap) => {
      const arr = [];
      snap.forEach((docSnap) => arr.push({ id: docSnap.id, ...docSnap.data() }));
      setResponses(arr);
    });
    return () => unsub();
  }, [request, requestsCollectionName]);

  // Timer
  useEffect(() => {
    if (!request) return;
    function calcTimeLeft() {
      if (!request.createdAt || !request.timeLimit) return 9999999999;
      const hours = parseFloat(request.timeLimit);
      if (isNaN(hours)) return 9999999999;
      const ms = hours * 3600 * 1000;
      const createdAtMs = request.createdAt?.toDate
        ? request.createdAt.toDate().getTime()
        : new Date(request.createdAt).getTime();
      return createdAtMs + ms - Date.now();
    }
    function update() { setTimeRemaining(calcTimeLeft()); }
    update();
    const t = setInterval(update, 1000);
    return () => clearInterval(t);
  }, [request]);

  const ended = timeRemaining <= 0;
  const maxRes = request?.maxNumberOfResponses || 1;
  const usedRes = responses.length;
  const remainRes = maxRes - usedRes;
  const canViewAllResponses =
    request?.showResponses || request?.authorId === userId;
  const isOwner = request?.authorId === userId;

  // boundary
  function isInBoundary() {
    if (!userLocation) return false;
    if (request.boundaryType === 'global') return true;
    try {
      if (request.boundaryType === 'radius' && request.boundary?.center) {
        const dist = window.google.maps.geometry.spherical.computeDistanceBetween(
          new window.google.maps.LatLng(userLocation.lat, userLocation.lng),
          new window.google.maps.LatLng(
            request.boundary.center.lat,
            request.boundary.center.lng
          )
        );
        const rad = (request.boundary.radius || 5) * 1000;
        return dist <= rad;
      }
      if (request.boundaryType === 'custom' && request.boundary?.points?.length) {
        const userLatLng = new window.google.maps.LatLng(userLocation.lat, userLocation.lng);
        const polygon = new window.google.maps.Polygon({ paths: request.boundary.points });
        return window.google.maps.geometry.poly.containsLocation(userLatLng, polygon);
      }
    } catch (err) {
      console.error('Boundary check error:', err);
    }
    return false;
  }

  const userCanSubmit = !isOwner && !ended && remainRes > 0 && isInBoundary();

  function formatTimeRemaining(ms) {
    if (ms <= 0) return 'Ends in: Ended';
    const totalSec = Math.floor(ms / 1000);
    const h = Math.floor(totalSec / 3600);
    const m = Math.floor((totalSec % 3600) / 60);
    const s = totalSec % 60;
    if (h > 0) return `Ends in: ${h}h ${m}m ${s}s`;
    if (m > 0) return `Ends in: ${m}m ${s}s`;
    return `Ends in: ${s}s`;
  }

  async function handleDeleteRequest() {
    if (!request?.id || !isOwner) return;
    try {
      await deleteDoc(doc(db, requestsCollectionName, request.id));
      onClose();
    } catch (err) {
      console.error(err);
      alert('Failed to delete request: ' + err.message);
    }
  }

  async function handleDeleteResponse(respId) {
    if (!respId) return;
    try {
      await deleteDoc(doc(db, requestsCollectionName, request.id, 'responses', respId));
      await recalcRewardDistribution();
    } catch (err) {
      console.error(err);
      alert('Failed to delete response: ' + err.message);
    }
  }

  // reward distribution
  async function recalcRewardDistribution() {
    if (!request?.id) return;
    const reqRef = doc(db, requestsCollectionName, request.id);
    const reqSnap = await getDoc(reqRef);
    if (!reqSnap.exists()) return;

    const requestData = reqSnap.data();
    const validResp = responses.filter((r) => r.status === 'valid');
    const totalScore = validResp.reduce((acc, r) => acc + (r.validityScore || 0), 0);

    let rewardLeft = requestData.rewardRemaining ?? requestData.reward;
    if (rewardLeft < 0) rewardLeft = 0;

    for (const r of validResp) {
      const fraction = totalScore > 0 ? (r.validityScore || 0) / totalScore : 0;
      r.distributedReward = rewardLeft * fraction;
    }
    // store
    for (const r of responses) {
      const rRef = doc(db, requestsCollectionName, request.id, 'responses', r.id);
      if (r.status === 'valid') {
        await updateDoc(rRef, { distributedReward: r.distributedReward });
      } else {
        await updateDoc(rRef, { distributedReward: 0 });
      }
    }
    await updateDoc(reqRef, { rewardRemaining: rewardLeft });
  }

  useEffect(() => {
    if (!request?.id) return;
    if (ended) {
      (async () => {
        await recalcRewardDistribution();
        await updateDoc(doc(db, requestsCollectionName, request.id), { status: 'ended' });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ended]);

  /////////////////////////////////////
  // SUBMIT RESPONSE
  /////////////////////////////////////
  async function handleSubmitResponse(e) {
    e.preventDefault();
    if (!wallet.connected) {
      alert('Connect wallet first');
      return;
    }
    if (!userCanSubmit) {
      alert('Not allowed to submit now (ended/out of range?).');
      return;
    }

    const subTypes = request.submissionTypes || [];
    // text
    if (subTypes.includes('text')) {
      const words = infoText.trim().split(/\s+/).filter(Boolean);
      if (words.length > 500) {
        alert('Max 500 words allowed in text field.');
        return;
      }
      if (!infoText.trim()) {
        alert('Text is required. Fill it in.');
        return;
      }
    }
    // images
    if (subTypes.includes('image') && imageSlots.length < 1) {
      alert('At least 1 image is required.');
      return;
    }
    // audio
    if (subTypes.includes('audio') && !audioFile) {
      alert('This request requires audio. Please upload/record it.');
      return;
    }

    // 1) Upload images to Storage => store just URL in Firestore
    // But for GPT, we also gather base64 to pass. 
    const imagesBase64ForGPT = [];
    const imagesUrlsForFirestore = [];
    try {
      for (const slot of imageSlots) {
        // Convert to base64 for GPT
        const b64 = slot.previewBase64; 
        imagesBase64ForGPT.push(b64);

        // Upload to Storage
        const storageRef = ref(storage, `responses/${Date.now()}-${slot.file.name}`);
        await uploadBytes(storageRef, slot.file);
        const dlUrl = await getDownloadURL(storageRef);
        imagesUrlsForFirestore.push(dlUrl);
      }
    } catch (uploadErr) {
      console.error('Image upload error:', uploadErr);
      alert('Failed to upload images: ' + uploadErr.message);
      return;
    }

    // 2) Audio
    let audioB64ForGPT = null;
    let audioUrlForFirestore = null;
    if (audioFile) {
      audioB64ForGPT = audioFilePreview; // the base64 or truncated
      // Upload to Storage
      try {
        const storageRef = ref(storage, `responses/${Date.now()}-${audioFile.name}`);
        await uploadBytes(storageRef, audioFile);
        audioUrlForFirestore = await getDownloadURL(storageRef);
      } catch (audioErr) {
        console.error('Audio upload error:', audioErr);
        alert('Failed to upload audio: ' + audioErr.message);
        return;
      }
    }

    // 3) Evaluate with GPT
    let matchScore = 100;
    let matchReasoning = '';
    try {
      const { score, reasoning } = await evaluateResponseMatch({
        requestText: request.question,
        responseText: infoText,
        imagesBase64: imagesBase64ForGPT,
        audioBase64: audioB64ForGPT
      });
      matchScore = score;
      matchReasoning = reasoning;
    } catch (err) {
      console.error('GPT error:', err);
    }

    if (matchScore < 50) {
      setInvalidFeedback(`Your response was < 50% relevant => invalid. Reason: ${matchReasoning}`);
    } else {
      setInvalidFeedback('');
    }

    // 4) Store short URLs in Firestore
    try {
      const respRef = collection(db, requestsCollectionName, request.id, 'responses');
      await addDoc(respRef, {
        text: infoText || null,
        author: wallet.publicKey?.toString() || 'Anonymous',
        authorId: userId,
        createdAt: Timestamp.now(),
        status: matchScore < 50 ? 'invalid' : 'valid',
        validityScore: matchScore,
        reasoning: matchReasoning,
        images: imagesUrlsForFirestore, // short URLs, not base64
        audio: audioUrlForFirestore,    // short URL
        distributedReward: 0
      });

      // Clear local form
      setInfoText('');
      setImageSlots([]);
      setAudioFile(null);
      setAudioFilePreview(null);
      setRecordedChunks([]);

      // Recalc distribution
      await recalcRewardDistribution();
    } catch (firestoreErr) {
      console.error('Failed to submit response:', firestoreErr);
      alert('Failed to submit response: ' + firestoreErr.message);
    }
  }

  //////////////////////////
  // FILE -> base64 Helper
  //////////////////////////
  function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (e) => reject(e);
      reader.readAsDataURL(file);
    });
  }

  // IMAGES
  async function handleImageUpload(files) {
    const newFiles = [...files];
    const combined = [...imageSlots];
    for (let i = 0; i < newFiles.length; i++) {
      if (combined.length >= 3) break; // limit 3
      const file = newFiles[i];
      const base64 = await fileToBase64(file);
      combined.push({ file, previewBase64: base64 });
    }
    setImageSlots(combined);
  }
  function removeImageSlot(idx) {
    const arr = [...imageSlots];
    arr.splice(idx, 1);
    setImageSlots(arr);
  }

  // AUDIO
  async function handleAudioUpload(fileObj) {
    setAudioFile(fileObj);
    const b64 = await fileToBase64(fileObj);
    setAudioFilePreview(b64);
  }
  async function startRecording() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mr = new MediaRecorder(stream, { mimeType: 'audio/webm' });
      mediaRecorderRef.current = mr;
      mr.ondataavailable = (e) => {
        if (e.data.size > 0) {
          setRecordedChunks((prev) => [...prev, e.data]);
        }
      };
      mr.start();
      setRecordTimer(0);
      recordIntervalRef.current = setInterval(() => {
        setRecordTimer((p) => p + 1);
      }, 1000);
      setIsRecording(true);
    } catch (err) {
      console.error(err);
      alert('Could not start recording: ' + err.message);
    }
  }
  function stopRecording() {
    if (!mediaRecorderRef.current) return;
    mediaRecorderRef.current.stop();
    mediaRecorderRef.current = null;
    setIsRecording(false);
    if (recordIntervalRef.current) {
      clearInterval(recordIntervalRef.current);
      recordIntervalRef.current = null;
    }
  }
  useEffect(() => {
    if (!isRecording && recordedChunks.length > 0) {
      const blob = new Blob(recordedChunks, { type: 'audio/webm' });
      const file = new File([blob], `recorded-audio-${Date.now()}.webm`, { type: 'audio/webm' });
      setAudioFile(file);
      fileToBase64(file).then((b64) => setAudioFilePreview(b64));
      setRecordedChunks([]);
    }
  }, [isRecording, recordedChunks]);

  useEffect(() => {
    if (isRecording && recordTimer >= 60) {
      stopRecording();
    }
  }, [recordTimer, isRecording]);

  function removeAudio() {
    setAudioFile(null);
    setAudioFilePreview(null);
    setRecordedChunks([]);
  }

  if (!request) return null;

  return (
    <div className="w-[400px] bg-[#1a1f2e] rounded-lg overflow-hidden ml-4 max-h-[calc(100vh-120px)] flex flex-col">
      {isEditing ? (
        <div className="p-6 flex-1 overflow-y-auto">
          <p className="text-white">Edit mode to update request. (Implementation optional)</p>
        </div>
      ) : (
        <>
          {/* Header */}
          <div className="flex-none p-6 border-b border-white/10">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-lg font-medium text-white">
                {isOwner ? 'Your Request' : 'Submit Response'}
              </h2>
              <div className="flex items-center gap-2">
                {isOwner && (
                  <>
                    <button
                      onClick={() => setIsEditing(true)}
                      className="text-white hover:text-gray-300 transition-colors"
                      title="Edit Request"
                    >
                      <Settings size={20} />
                    </button>
                    <button
                      onClick={handleDeleteRequest}
                      className="text-red-500 hover:text-red-400 transition-colors"
                      title="Delete Request"
                    >
                      <Trash2 size={20} />
                    </button>
                  </>
                )}
                <button
                  onClick={onClose}
                  className="text-white/60 hover:text-white"
                >
                  <X size={20} />
                </button>
              </div>
            </div>

            <h3 className="text-white font-medium mb-2">{request.question}</h3>

            <div className="flex items-center justify-between text-sm text-white/60">
              <div className="flex items-center gap-2">
                <Clock className="w-4 h-4" />
                <span>{formatTimeRemaining(timeRemaining)}</span>
              </div>
              {ended ? (
                <span className="text-red-500">Ended</span>
              ) : request.boundaryType === 'global' ? (
                <span className="text-blue-400">Global</span>
              ) : (
                <span className="text-green-500">Local/Region</span>
              )}
            </div>

            <div className="text-xs text-white/60 mt-2">
              {remainRes} responses left (out of {request.maxNumberOfResponses || 1})
            </div>
            {request.averageEarningPerResponse > 0 && (
              <div className="text-xs text-yellow-400 mt-2">
                Average earning per valid submission: $
                {request.averageEarningPerResponse.toFixed(2)}
              </div>
            )}
            <div className="text-xs text-white/60 mt-2">
              Submission allowed: {(request.submissionTypes || []).join(', ') || 'text'}
            </div>
            <div className="text-xs text-cyan-300 mt-2">
              Remaining Reward: $
              {request.rewardRemaining?.toFixed(2) ?? request.reward}
            </div>
          </div>

          {/* Body */}
          <div className="flex-1 overflow-y-auto p-6">
            {!isOwner && !ended && remainRes > 0 && userCanSubmit && (
              <div className="mb-8">
                <form onSubmit={handleSubmitResponse}>
                  {/* text */}
                  {request.submissionTypes?.includes('text') && (
                    <div className="mb-4">
                      <label className="block text-gray-400 mb-2">
                        Your Response (max 500 words)
                      </label>
                      <textarea
                        value={infoText}
                        onChange={(e) => setInfoText(e.target.value)}
                        className="w-full h-24 bg-[#262d40] rounded-lg p-4 text-white 
                          placeholder:text-white/40 border border-transparent
                          focus:border-green-500/50 resize-none"
                        placeholder="Enter your text response..."
                      />
                    </div>
                  )}

                  {/* images */}
                  {request.submissionTypes?.includes('image') && (
                    <div className="mb-4">
                      <label className="block text-gray-400 mb-2">
                        Your Response (Images) - max 3
                      </label>
                      <div className="flex items-center gap-4 mb-2">
                        <button
                          type="button"
                          onClick={() => document.getElementById('files-images')?.click()}
                          className="px-4 py-2 bg-[#262d40] text-white/60 hover:text-white rounded-lg flex items-center gap-2"
                        >
                          <Upload className="w-4 h-4" />
                          Upload
                        </button>
                        <input
                          id="files-images"
                          type="file"
                          accept="image/*"
                          multiple
                          className="hidden"
                          onChange={(e) => handleImageUpload(e.target.files)}
                        />
                      </div>
                      <div className="flex flex-wrap gap-3">
                        {imageSlots.map((img, idx) => (
                          <div key={idx} className="relative w-20 h-20">
                            <img
                              src={img.previewBase64}
                              alt={`preview-${idx}`}
                              className="w-20 h-20 object-cover rounded"
                            />
                            <button
                              type="button"
                              onClick={() => removeImageSlot(idx)}
                              className="absolute top-1 right-1 text-red-500"
                            >
                              <Trash2 size={14} />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* audio */}
                  {request.submissionTypes?.includes('audio') && (
                    <div className="mb-4">
                      <label className="block text-gray-400 mb-2">
                        Your Response (Audio) - up to 1 min
                      </label>
                      <div className="flex items-center gap-3 flex-wrap">
                        <button
                          type="button"
                          onClick={() => document.getElementById('file-audio')?.click()}
                          className="px-4 py-2 bg-[#262d40] text-white/60 hover:text-white rounded-lg flex items-center gap-2"
                        >
                          <Upload className="w-4 h-4" />
                          Upload Audio
                        </button>
                        <input
                          id="file-audio"
                          type="file"
                          accept="audio/*"
                          className="hidden"
                          onChange={(e) => {
                            if (e.target.files?.[0]) handleAudioUpload(e.target.files[0]);
                          }}
                        />

                        {!isRecording ? (
                          <button
                            type="button"
                            onClick={startRecording}
                            className="px-4 py-2 bg-green-600 text-white rounded flex items-center gap-2"
                          >
                            <Mic className="w-4 h-4" />
                            Record
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={stopRecording}
                            className="px-4 py-2 bg-red-600 text-white rounded flex items-center gap-2"
                          >
                            <StopCircle className="w-4 h-4" />
                            Stop
                          </button>
                        )}
                        {isRecording && (
                          <span className="text-sm text-white/60">
                            Recording... {recordTimer}s
                          </span>
                        )}
                        {audioFile && (
                          <div className="flex flex-col items-start gap-1">
                            <audio controls src={audioFilePreview || ''} className="mt-2 max-w-full" />
                            <button
                              type="button"
                              onClick={removeAudio}
                              className="text-red-400 flex items-center gap-1 text-sm"
                            >
                              <Trash2 size={14} />
                              Remove Audio
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <button
                    type="submit"
                    className="w-full py-3 bg-green-500 text-white hover:bg-green-600 rounded-lg transition-colors"
                  >
                    Submit Response
                  </button>
                </form>

                {invalidFeedback && (
                  <div className="mt-4 text-sm text-red-500">{invalidFeedback}</div>
                )}
              </div>
            )}

            <h3 className="text-white font-medium mb-4 sticky top-0">
              Responses ({responses.length})
            </h3>
            {responses.length === 0 ? (
              <div className="text-center text-white/40">No responses yet</div>
            ) : canViewAllResponses ? (
              <div className="space-y-4">
                {responses.map((resp) => (
                  <div key={resp.id} className="bg-[#262d40] rounded-lg p-4 relative">
                    <div className="flex justify-between items-start mb-2">
                      <div>
                        <span className="text-sm text-white/60">
                          {resp.authorId === userId
                            ? 'You'
                            : (resp.authorId || '').slice(0, 8) + '...'}
                        </span>
                        <span className="text-xs text-white/40 ml-2">
                          {resp.createdAt?.toDate
                            ? resp.createdAt.toDate().toLocaleString()
                            : new Date(resp.createdAt).toLocaleString()}
                        </span>
                      </div>
                      {(isOwner || resp.authorId === userId) && (
                        <button
                          onClick={() => handleDeleteResponse(resp.id)}
                          className="text-red-500 hover:text-red-400 transition-colors"
                          title="Delete Response"
                        >
                          <Trash2 size={16} />
                        </button>
                      )}
                    </div>
                    {resp.status === 'invalid' && (
                      <div className="text-xs text-red-400 mb-1">
                        Invalid (<span className="font-bold">{resp.validityScore}%</span> match)
                      </div>
                    )}
                    {resp.status === 'valid' && (
                      <div className="text-xs text-green-400 mb-1">
                        Valid (<span className="font-bold">{resp.validityScore}%</span> match)
                      </div>
                    )}
                    {resp.reasoning && (
                      <div className="text-xs text-white/50 italic mb-2">
                        GPT-4 reasoning: {resp.reasoning}
                      </div>
                    )}
                    {resp.text && <p className="text-white mb-2">{resp.text}</p>}

                    {/* Show Firestore URLs, no longer big base64 */}
                    {Array.isArray(resp.images) && resp.images.length > 0 && (
                      <div className="flex flex-wrap gap-2 mb-2">
                        {resp.images.map((url, idx) => (
                          <img
                            key={idx}
                            src={url}
                            alt={`resp-img-${idx}`}
                            className="max-w-[120px] max-h-40 object-cover rounded border border-white/10"
                          />
                        ))}
                      </div>
                    )}

                    {resp.audio && (
                      <div>
                        <audio controls className="w-full mt-1">
                          <source src={resp.audio} type="audio/webm" />
                          Your browser does not support audio playback.
                        </audio>
                      </div>
                    )}
                    {resp.distributedReward > 0 && resp.status === 'valid' && (
                      <div className="text-xs text-emerald-400 mt-2">
                        Potential Earned: ${resp.distributedReward.toFixed(2)}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-white/50">Responses are hidden by the publisher.</div>
            )}
          </div>
        </>
      )}
    </div>
  );
}