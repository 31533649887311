
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyC8mgwsJwXSa6mLkSw6q5QX0ssek-l5E5U",
  authDomain: "locallens-5cf17.firebaseapp.com",
  databaseURL: "https://locallens-5cf17-default-rtdb.firebaseio.com",
  projectId: "locallens-5cf17",
  storageBucket: "locallens-5cf17.firebaseapp.com",
  messagingSenderId: "876205328855",
  appId: "1:876205328855:web:10b96a98b2dc981c126392",
  measurementId: "G-5108XLEPC0"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

class FirebaseManager {
  constructor() {
    this.currentEnvironment = 'development'; // you can still switch if desired
    this.currentLedger = 'live';
  }

  switchEnvironment(environment) {
    this.currentEnvironment = environment;
  }

  setLedger(ledger) {
    if (this.currentEnvironment === 'development' && ledger === 'test') {
      this.currentLedger = 'test';
    } else if (this.currentEnvironment === 'production') {
      this.currentLedger = 'live';
    }
  }

  getCurrentEnvironment() {
    return this.currentEnvironment;
  }

  getCurrentLedger() {
    return this.currentLedger;
  }

  getCollectionPrefix() {
    const envPrefix = this.currentEnvironment === 'production' ? 'prod' : 'dev';
    const ledgerPrefix = this.currentLedger;
    return `${envPrefix}_${ledgerPrefix}`;
  }

  getCollection(collectionName) {
    // If you want dynamic collection names based on environment/ledger, uncomment the next line:
    // return `${this.getCollectionPrefix()}_${collectionName}`;
    // Otherwise, just return the plain name:
    return collectionName;
  }

  logEnvironmentInfo() {
    console.log('Environment:', this.currentEnvironment);
    console.log('Ledger:', this.currentLedger);
    console.log('Collection Prefix:', this.getCollectionPrefix());
  }
}

const firebaseManager = new FirebaseManager();

export { app, analytics, db, firebaseManager };