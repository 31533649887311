// BusinessForm.js
import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, Polygon, DrawingManager } from '@react-google-maps/api';
import { Search, Store } from 'lucide-react';

const BusinessForm = ({ onSubmit, onClose, userLocation, isMasterWallet }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    location: userLocation || null,
    address: '',
    boundaryType: 'pin',
    boundary: null,
    category: '',
    website: '',
    phone: '',
  });
  const [map, setMap] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [drawingMode, setDrawingMode] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState('pending');

  useEffect(() => {
    if (userLocation && !formData.location) {
      setFormData(prev => ({
        ...prev,
        location: userLocation
      }));
      
      if (map) {
        map.panTo(userLocation);
        map.setZoom(18);
      }
    }
  }, [userLocation, map]);

  const verifyLocation = () => {
    if (isMasterWallet) return true;
    if (!userLocation || !formData.location) return false;

    const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
      new window.google.maps.LatLng(userLocation.lat, userLocation.lng),
      new window.google.maps.LatLng(formData.location.lat, formData.location.lng)
    );

    return distance <= 100; // Within 100 meters
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!verifyLocation()) {
      setVerificationStatus('failed');
      return;
    }

    try {
      await onSubmit(formData);
      setVerificationStatus('success');
    } catch (error) {
      console.error('Error submitting business:', error);
      setVerificationStatus('error');
    }
  };

  const handleMapClick = (e) => {
    if (formData.boundaryType === 'pin') {
      const newLocation = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng()
      };
      
      setFormData(prev => ({
        ...prev,
        location: newLocation
      }));

      if (map) {
        map.panTo(newLocation);
        map.setZoom(18);
      }
    }
  };

  const onBoundaryComplete = (polygon) => {
    const points = polygon.getPath().getArray().map(point => ({
      lat: point.lat(),
      lng: point.lng()
    }));

    const bounds = new window.google.maps.LatLngBounds();
    points.forEach(point => bounds.extend(point));
    const center = bounds.getCenter();

    setFormData(prev => ({
      ...prev,
      location: {
        lat: center.lat(),
        lng: center.lng()
      },
      boundary: {
        points,
        center: {
          lat: center.lat(),
          lng: center.lng()
        }
      }
    }));

    setDrawingMode(false);
  };

  const searchPlaces = () => {
    if (!map || !searchQuery) return;

    const service = new window.google.maps.places.PlacesService(map);
    const request = {
      query: searchQuery,
      fields: ['name', 'geometry', 'formatted_address']
    };

    service.findPlaceFromQuery(request, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && results[0]) {
        const place = results[0];
        const location = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        };

        setFormData(prev => ({
          ...prev,
          name: place.name,
          location: location,
          address: place.formatted_address
        }));

        if (map) {
          map.panTo(location);
          map.setZoom(18);
        }
      }
    });
  };

  const onMapLoad = (mapInstance) => {
    setMap(mapInstance);
    if (formData.location) {
      mapInstance.panTo(formData.location);
      mapInstance.setZoom(18);
    }
  };

  return (
    <div className="max-h-[calc(100vh-200px)] overflow-y-auto">
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Search Section */}
        <div>
          <label className="block text-gray-400 mb-2">Search Business</label>
          <div className="flex gap-2">
            <div className="relative flex-1">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && (e.preventDefault(), searchPlaces())}
                className="w-full bg-[#262d40] rounded-lg p-4 pl-12 text-white 
                         border border-transparent focus:border-orange-500"
                placeholder="Search for business on Google Maps..."
              />
              <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
            </div>
            <button
              type="button"
              onClick={searchPlaces}
              className="px-6 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
            >
              Search
            </button>
          </div>
        </div>

        {/* Business Details */}
        <div className="space-y-4">
          <div>
            <label className="block text-gray-400 mb-2">Business Name</label>
            <input
              type="text"
              value={formData.name}
              onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
              className="w-full bg-[#262d40] rounded-lg p-4 text-white 
                     border border-transparent focus:border-orange-500"
              placeholder="Enter business name"
              required
            />
          </div>

          <div>
            <label className="block text-gray-400 mb-2">Description</label>
            <textarea
              value={formData.description}
              onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
              className="w-full h-32 bg-[#262d40] rounded-lg p-4 text-white 
                     border border-transparent focus:border-orange-500 resize-none"
              placeholder="Enter business description"
              required
            />
          </div>

          <div>
            <label className="block text-gray-400 mb-2">Category</label>
            <input
              type="text"
              value={formData.category}
              onChange={(e) => setFormData(prev => ({ ...prev, category: e.target.value }))}
              className="w-full bg-[#262d40] rounded-lg p-4 text-white 
                     border border-transparent focus:border-orange-500"
              placeholder="Business category"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-400 mb-2">Website</label>
              <input
                type="url"
                value={formData.website}
                onChange={(e) => setFormData(prev => ({ ...prev, website: e.target.value }))}
                className="w-full bg-[#262d40] rounded-lg p-4 text-white 
                       border border-transparent focus:border-orange-500"
                placeholder="https://..."
              />
            </div>
            <div>
              <label className="block text-gray-400 mb-2">Phone</label>
              <input
                type="tel"
                value={formData.phone}
                onChange={(e) => setFormData(prev => ({ ...prev, phone: e.target.value }))}
                className="w-full bg-[#262d40] rounded-lg p-4 text-white 
                       border border-transparent focus:border-orange-500"
                placeholder="Phone number"
              />
            </div>
          </div>
        </div>

        {/* Location Section */}
        <div>
          <label className="block text-gray-400 mb-2">Location Type</label>
          <div className="flex gap-4 mb-4">
            <button
              type="button"
              onClick={() => setFormData(prev => ({ ...prev, boundaryType: 'pin' }))}
              className={`flex-1 py-2 rounded-lg ${
                formData.boundaryType === 'pin'
                  ? 'bg-orange-500 text-white'
                  : 'bg-[#262d40] text-white/60'
              }`}
            >
              Pin Location
            </button>
            <button
              type="button"
              onClick={() => setFormData(prev => ({ ...prev, boundaryType: 'boundary' }))}
              className={`flex-1 py-2 rounded-lg ${
                formData.boundaryType === 'boundary'
                  ? 'bg-orange-500 text-white'
                  : 'bg-[#262d40] text-white/60'
              }`}
            >
              Draw Boundary
            </button>
          </div>

          <div className="h-64 bg-[#262d40] rounded-lg overflow-hidden">
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '100%' }}
              center={formData.location || userLocation || { lat: 0, lng: 0 }}
              zoom={18}
              onLoad={onMapLoad}
              onClick={handleMapClick}
              options={{
                mapTypeId: 'hybrid',
                disableDefaultUI: false,
                zoomControl: true,
                fullscreenControl: false
              }}
            >
              {formData.location && (
                <Marker
                  position={formData.location}
                  icon={{
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 10,
                    fillColor: '#22c55e',
                    fillOpacity: 1,
                    strokeColor: '#ffffff',
                    strokeWeight: 2
                  }}
                />
              )}

              {formData.boundaryType === 'boundary' && (
                <DrawingManager
                  onPolygonComplete={onBoundaryComplete}
                  options={{
                    drawingMode: drawingMode ? window.google.maps.drawing.OverlayType.POLYGON : null,
                    drawingControl: true,
                    drawingControlOptions: {
                      position: window.google.maps.ControlPosition.TOP_CENTER,
                      drawingModes: [window.google.maps.drawing.OverlayType.POLYGON]
                    },
                    polygonOptions: {
                      fillColor: '#22c55e',
                      fillOpacity: 0.2,
                      strokeColor: '#22c55e',
                      strokeWeight: 2
                    }
                  }}
                />
              )}

              {formData.boundary && (
                <Polygon
                  paths={formData.boundary.points}
                  options={{
                    fillColor: '#22c55e',
                    fillOpacity: 0.2,
                    strokeColor: '#22c55e',
                    strokeWeight: 2
                  }}
                />
              )}
            </GoogleMap>
          </div>
        </div>

        {verificationStatus === 'failed' && (
          <div className="bg-red-500/10 text-red-500 p-4 rounded-lg">
            Location verification failed. Please ensure you're at the business location.
          </div>
        )}

        {/* Form Actions */}
        <div className="flex gap-4 pt-4">
          <button
            type="button"
            onClick={onClose}
            className="flex-1 py-3 rounded-lg text-white hover:bg-[#262d40] transition-colors"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="flex-1 py-3 rounded-lg bg-green-500 text-white hover:bg-green-600 
                     transition-colors flex items-center justify-center gap-2"
          >
            <Store className="w-5 h-5" />
            Add Business
          </button>
        </div>
      </form>
    </div>
  );
};

export default BusinessForm;