// utility.js
import { 
    collection, 
    addDoc, 
    serverTimestamp,
    query,
    orderBy,
    limit,
    getDocs
  } from 'firebase/firestore';
  import { db, firebaseManager } from './firebaseConfig';
  import { sha256 } from 'js-sha256';
  
  const getStateTransitionsCollection = () => {
    const currentEnv = firebaseManager.getCurrentEnvironment();
    const currentLedger = firebaseManager.getCurrentLedger();
    
    if (currentEnv === 'development') {
      return currentLedger === 'live' ? 'stateTransitions_dev_live' : 'stateTransitions_dev_test';
    }
    return 'stateTransitions_prod_live';
  };
  
  export const addBlock = async ({ event, request = null, business = null }) => {
    const collectionName = getStateTransitionsCollection();
    
    // Get the latest block to get its hash
    const latestBlocksQuery = query(
      collection(db, collectionName),
      orderBy('timestamp', 'desc'),
      limit(1)
    );
  
    try {
      const querySnapshot = await getDocs(latestBlocksQuery);
      let previousHash = null;
      
      if (!querySnapshot.empty) {
        previousHash = querySnapshot.docs[0].data().hash;
      }
  
      // Create the block data
      const blockData = {
        event,
        data: request || business,
        timestamp: serverTimestamp(),
      };
  
      // Calculate hash of the block
      const blockString = JSON.stringify({
        ...blockData,
        previousHash
      });
      const hash = sha256(blockString);
  
      // Create the new block
      const newBlock = {
        ...blockData,
        hash,
        previousHash
      };
  
      // Add the block to the chain
      await addDoc(collection(db, collectionName), newBlock);
  
    } catch (error) {
      console.error('Error adding block to chain:', error);
      throw error;
    }
  };