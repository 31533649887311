import React, { useState, useEffect } from 'react';
import { LoadScript } from '@react-google-maps/api';
import { useWallet } from '@solana/wallet-adapter-react';
import {
  collection,
  addDoc,
  onSnapshot,
  query,
  orderBy,
  doc,
  setDoc,
  getDoc,
  serverTimestamp
} from 'firebase/firestore';
import SidebarContainer from './SidebarContainer';
import MainMap from './MainMap';
import PostRequestForm from './PostRequestForm';
import BusinessForm from './BusinessForm';
import ChainViewComponent from './ChainViewComponent';
import { X, MapPin, Plus } from 'lucide-react';
import '@solana/wallet-adapter-react-ui/styles.css';
import { db, firebaseManager } from './firebaseConfig';
import { addBlock } from './utility';
import BusinessDetailsPanel from './BusinessDetailsPanel';

const GOOGLE_MAPS_API_KEY = 'YOUR_GOOGLE_MAPS_KEY'; // your real key
const MASTER_WALLET = 'Ai5oNL8qvQGE2dbYjzXahYvT44KoQ73zrUwsoPNr6PNB';
const libraries = ['places', 'visualization', 'geometry', 'drawing'];

const NetworkSelector = ({ onEnvironmentChange, chainView, onChainViewToggle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [network, setNetwork] = useState(firebaseManager.getCurrentEnvironment());
  const [ledger, setLedger] = useState(firebaseManager.getCurrentLedger());

  const handleNetworkChange = (newNetwork) => {
    firebaseManager.switchEnvironment(newNetwork);
    if (newNetwork === 'production') {
      firebaseManager.setLedger('live');
      setLedger('live');
    }
    setNetwork(newNetwork);
    setIsOpen(false);
    firebaseManager.logEnvironmentInfo();
    onEnvironmentChange();
  };

  const handleLedgerChange = (newLedger) => {
    if (firebaseManager.getCurrentEnvironment() === 'development') {
      firebaseManager.setLedger(newLedger);
      setLedger(newLedger);
      setIsOpen(false);
      firebaseManager.logEnvironmentInfo();
      onEnvironmentChange();
    }
  };

  return (
    <div className="flex items-center justify-between w-full max-w-6xl mx-auto px-4 relative z-50">
      <div className="flex items-center gap-2">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center gap-2 bg-orange-500 px-3 py-1.5 rounded-lg text-sm text-white hover:bg-orange-600 transition-colors"
        >
          <span className="capitalize">{network}</span>
          {ledger !== 'live' && network === 'development' && (
            <span className="text-white text-xs">({ledger})</span>
          )}
        </button>
      </div>

      <div className="flex items-center gap-4">
        <button
          onClick={onChainViewToggle}
          className={`px-3 py-1.5 rounded-lg text-sm transition-colors ${
            chainView
              ? 'bg-green-500 text-white hover:bg-green-600'
              : 'bg-orange-500 text-white hover:bg-orange-600'
          }`}
        >
          {chainView ? 'Exit Chain View' : 'Chain View'}
        </button>
      </div>

      {isOpen && (
        <div className="absolute top-full mt-2 w-48 bg-[#1a1f2e] rounded-lg shadow-xl border border-white/10 p-2">
          <div className="mb-2">
            <div className="text-xs text-white/40 mb-1 px-2">Network</div>
            <div className="space-y-1">
              {['development', 'production'].map((env) => (
                <button
                  key={env}
                  onClick={() => handleNetworkChange(env)}
                  className={`w-full px-2 py-1.5 rounded text-left text-sm flex items-center gap-2
                    ${
                      network === env
                        ? 'bg-orange-500 text-white'
                        : 'text-white/70 hover:bg-white/5'
                    }`}
                >
                  <span className="capitalize">{env}</span>
                </button>
              ))}
            </div>
          </div>

          {network === 'development' && (
            <div>
              <div className="text-xs text-white/40 mb-1 px-2">Blockchain</div>
              <div className="space-y-1">
                {['live', 'test'].map((type) => (
                  <button
                    key={type}
                    onClick={() => handleLedgerChange(type)}
                    className={`w-full px-2 py-1.5 rounded text-left text-sm flex items-center gap-2
                      ${
                        ledger === type
                          ? 'bg-orange-500 text-white'
                          : 'text-white/70 hover:bg-white/5'
                      }`}
                  >
                    <span className="capitalize">{type} Chain</span>
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const App = () => {
  const wallet = useWallet();
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({ lat: 20, lng: 0 });
  const [userLocation, setUserLocation] = useState(null);
  const [activeTab, setActiveTab] = useState('requests');
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [requests, setRequests] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [localLensBalance, setLocalLensBalance] = useState(0); // used as "dollar" balance
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [isBusinessFormOpen, setIsBusinessFormOpen] = useState(false);
  const [locationError, setLocationError] = useState('');
  const [environmentKey, setEnvironmentKey] = useState(0);
  const [chainView, setChainView] = useState(false);

  const [isUpdatingLocation, setIsUpdatingLocation] = useState(false);
  const [newLocation, setNewLocation] = useState(null);

  const getCollections = () => {
    const currentEnv = firebaseManager.getCurrentEnvironment();
    const currentLedger = firebaseManager.getCurrentLedger();

    let requestsCollectionName = 'requests';
    let businessesCollectionName = 'businesses';

    if (currentEnv === 'development') {
      requestsCollectionName =
        currentLedger === 'live' ? 'requests_dev_live' : 'requests_dev_test';
      businessesCollectionName =
        currentLedger === 'live' ? 'businesses_dev_live' : 'businesses_dev_test';
    } else {
      requestsCollectionName = 'requests_prod_live';
      businessesCollectionName = 'businesses_prod_live';
    }

    return { requestsCollectionName, businessesCollectionName };
  };

  useEffect(() => {
    const { requestsCollectionName } = getCollections();
    const qReq = query(collection(db, requestsCollectionName), orderBy('createdAt', 'desc'));

    const unsubscribeRequests = onSnapshot(qReq, (querySnapshot) => {
      const requestsData = [];
      querySnapshot.forEach((docSnap) => {
        requestsData.push({ id: docSnap.id, ...docSnap.data() });
      });
      setRequests(requestsData);
    });

    return () => unsubscribeRequests();
  }, [environmentKey]);

  useEffect(() => {
    const { businessesCollectionName } = getCollections();
    const qBiz = query(collection(db, businessesCollectionName), orderBy('createdAt', 'desc'));
    const unsubscribeBusinesses = onSnapshot(
      qBiz,
      (querySnapshot) => {
        const businessData = [];
        querySnapshot.forEach((docSnap) => {
          businessData.push({ id: docSnap.id, ...docSnap.data() });
        });
        setBusinesses(businessData);
      },
      (error) => {
        console.error('Error fetching businesses:', error);
      }
    );

    return () => unsubscribeBusinesses();
  }, [environmentKey]);

  useEffect(() => {
    const fetchTokenData = async () => {
      if (wallet.publicKey) {
        const usersCollection = firebaseManager.getCollection('users');
        const userRef = doc(db, usersCollection, wallet.publicKey.toString());
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          // We'll treat this as the "usd" balance
          setLocalLensBalance(userData.localLensBalance || 0);
          setPurchaseHistory(userData.purchaseHistory || []);

          if (userData.location) {
            setUserLocation(userData.location);
          }
        } else {
          await setDoc(userRef, {
            localLensBalance: 0,
            purchaseHistory: []
          });
        }
      }
    };

    if (wallet.connected) {
      fetchTokenData();
    } else {
      setLocalLensBalance(0);
      setPurchaseHistory([]);
    }
  }, [wallet.connected, wallet.publicKey, environmentKey]);

  const handleUpdateLocation = async (newLocation) => {
    if (!wallet.connected) return;

    try {
      const usersCollection = firebaseManager.getCollection('users');
      const userRef = doc(db, usersCollection, wallet.publicKey.toString());
      await setDoc(
        userRef,
        {
          location: newLocation
        },
        { merge: true }
      );

      setUserLocation(newLocation);
      setLocationError('');

      if (map) {
        map.panTo(newLocation);
        map.setZoom(18);
      }
    } catch (error) {
      console.error('Error updating location:', error);
      setLocationError('Failed to update location');
    }
  };

  const handleSetNewLocation = async () => {
    if (newLocation) {
      await handleUpdateLocation(newLocation);
      setIsUpdatingLocation(false);
      setNewLocation(null);
    }
  };

  // *** CHANGED: We replaced costInDollars with averageEarningPerResponse *** 
  const handlePostRequest = async (formData) => {
    try {
      const { requestsCollectionName } = getCollections();
      const newRequest = {
        author: wallet.publicKey?.toString() || 'Anonymous',
        authorId: wallet.publicKey?.toString(),
        posted: 'just now',
        reward: formData.reward,
        currentPrice: formData.reward,
        originalPrice: formData.reward,
        question: formData.question,
        timeLimit: `${formData.timeLimit}`, // store as hours
        infoCount: 0,
        makerLocation: userLocation,
        boundary: formData.boundary,
        boundaryType: formData.boundaryType || 'radius',
        createdAt: serverTimestamp(),
        status: 'active',
        isBot: false,
        type: formData.type || 'TEXT',
        maxNumberOfResponses: formData.maxNumberOfResponses,
        // Renamed costInDollars -> averageEarningPerResponse
        averageEarningPerResponse: formData.averageEarningPerResponse,
        showResponses: formData.showResponses,
        submissionTypes: formData.submissionTypes,
        // track how much reward remains:
        rewardRemaining: formData.reward
      };

      const docRef = await addDoc(collection(db, requestsCollectionName), newRequest);
      await addBlock({
        event: 'new_request',
        request: { ...newRequest, id: docRef.id }
      });

      setIsPostModalOpen(false);
    } catch (error) {
      console.error('Error in handlePostRequest:', error);
      alert('Failed to save request: ' + error.message);
    }
  };

  const handleBuyTokens = async (amount, pricePerToken) => {
    if (!wallet.connected) return;

    try {
      const usersCollection = firebaseManager.getCollection('users');
      const userRef = doc(db, usersCollection, wallet.publicKey.toString());
      const userDoc = await getDoc(userRef);
      const currentBalance = userDoc.exists()
        ? userDoc.data().localLensBalance || 0
        : 0;
      const currentHistory = userDoc.exists()
        ? userDoc.data().purchaseHistory || []
        : [];

      const purchase = {
        amount,
        pricePerToken,
        timestamp: new Date().toISOString()
      };

      // treat this as adding dollars
      await setDoc(
        userRef,
        {
          localLensBalance: currentBalance + amount,
          purchaseHistory: [...currentHistory, purchase]
        },
        { merge: true }
      );

      setLocalLensBalance(currentBalance + amount);
      setPurchaseHistory([...currentHistory, purchase]);
    } catch (error) {
      console.error('Error buying tokens:', error);
      alert('Failed to purchase tokens: ' + error.message);
    }
  };

  const handleAddBusiness = async (businessData) => {
    if (!wallet.connected) return;

    try {
      const { businessesCollectionName } = getCollections();
      const newBusiness = {
        ...businessData,
        owner: wallet.publicKey.toString(),
        createdAt: serverTimestamp()
      };

      const docRef = await addDoc(collection(db, businessesCollectionName), newBusiness);
      await addBlock({
        event: 'new_business',
        business: { ...newBusiness, id: docRef.id }
      });

      setIsBusinessFormOpen(false);
      setActiveTab('businesses');
    } catch (error) {
      console.error('Error adding business:', error);
      alert('Failed to add business: ' + error.message);
    }
  };

  const handleItemSelect = (item, type) => {
    setSelectedRequest(null);
    setSelectedBusiness(null);

    switch (type) {
      case 'request':
        setSelectedRequest(item);
        break;
      case 'business':
        setSelectedBusiness(item);
        break;
      default:
        break;
    }

    if (map && item?.location) {
      map.panTo(item.location);
      map.setZoom(15);
    }
  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          setUserLocation(newLocation);
          setCenter(newLocation);

          if (map) {
            map.panTo(newLocation);
            map.setZoom(16);
          }

          if (wallet.connected) {
            handleUpdateLocation(newLocation);
          }
        },
        (error) => {
          console.error('Error getting location:', error);
          setLocationError('Failed to get your location');
        }
      );
    } else {
      setLocationError('Your browser does not support geolocation');
    }
  };

  const timeAgo = (date) => {
    if (!date) return 'unknown';

    let past;
    if (date.toDate && typeof date.toDate === 'function') {
      past = date.toDate();
    } else {
      past = new Date(date);
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now - past) / 1000);

    if (diffInSeconds < 60) return `${diffInSeconds}s ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h ago`;
    return `${Math.floor(diffInSeconds / 86400)}d ago`;
  };

  const handleEnvironmentChange = () => {
    setEnvironmentKey((prevKey) => prevKey + 1);
    setSelectedRequest(null);
    setSelectedBusiness(null);
  };

  const onChainViewToggle = () => {
    setChainView(!chainView);
  };

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={libraries}>
      <div className="h-screen w-screen fixed inset-0 overflow-hidden">
        <div className="absolute top-0 left-0 right-0 w-full z-50 bg-orange-500/90 backdrop-blur-sm border-b border-orange-500/20">
          <NetworkSelector
            onEnvironmentChange={handleEnvironmentChange}
            chainView={chainView}
            onChainViewToggle={onChainViewToggle}
          />
        </div>

        <div className="flex flex-col h-full">
          {chainView ? (
            <ChainViewComponent environmentKey={environmentKey} />
          ) : (
            <>
              <MainMap
                map={map}
                setMap={setMap}
                center={center}
                userLocation={userLocation}
                requests={requests}
                businesses={businesses}
                activeTab={activeTab}
                selectedRequest={selectedRequest}
                selectedBusiness={selectedBusiness}
                userId={wallet.publicKey?.toString()}
                handleItemSelect={handleItemSelect}
                isUpdatingLocation={isUpdatingLocation}
                newLocation={newLocation}
                setNewLocation={setNewLocation}
              />

              <SidebarContainer
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                requests={requests}
                businesses={businesses}
                timeAgo={timeAgo}
                selectedRequest={selectedRequest}
                selectedBusiness={selectedBusiness}
                handleItemSelect={handleItemSelect}
                transactions={[]}
                userLocation={userLocation}
                userId={wallet.publicKey?.toString()}
                localLensBalance={localLensBalance}
                onBuyTokens={handleBuyTokens}
                purchaseHistory={purchaseHistory}
                onAddBusiness={() => setIsBusinessFormOpen(true)}
                onUpdateLocation={handleUpdateLocation}
                networkBarHeight={38}
                isUpdatingLocation={isUpdatingLocation}
                setIsUpdatingLocation={setIsUpdatingLocation}
                handleSetNewLocation={handleSetNewLocation}
              />

              {activeTab === 'businesses' && selectedBusiness && (
                <BusinessDetailsPanel
                  business={selectedBusiness}
                  onClose={() => handleItemSelect(null, null)}
                  userLocation={userLocation}
                />
              )}

              <div className="absolute bottom-6 right-6">
                <button
                  onClick={() => {
                    if (!wallet.connected) {
                      alert('Please connect your wallet first');
                      return;
                    }
                    if (!userLocation) {
                      getUserLocation();
                    } else {
                      if (activeTab === 'businesses') {
                        setIsBusinessFormOpen(true);
                      } else {
                        setIsPostModalOpen(true);
                      }
                    }
                  }}
                  className="px-8 py-3 bg-green-500 text-white rounded-full font-medium 
                       hover:bg-green-600 transition-all duration-300 
                       flex items-center gap-2"
                >
                  {!userLocation ? (
                    <>
                      <MapPin className="w-5 h-5" />
                      Enable Location
                    </>
                  ) : activeTab === 'businesses' ? (
                    <>
                      <Plus className="w-5 h-5" />
                      Add Business
                    </>
                  ) : (
                    'Post Request'
                  )}
                </button>
              </div>

              {isPostModalOpen && (
                <div className="fixed inset-0 bg-black/95 z-[100] flex items-center justify-center">
                  <div
                    className="bg-[#1a1f2e]/90 backdrop-blur-xl rounded-2xl max-w-2xl w-full mx-4 max-h-[90vh] overflow-y-auto
                           border border-white/10 shadow-2xl shadow-black/50 relative"
                  >
                    <div className="p-8">
                      <div className="flex justify-between items-center mb-6">
                        <h2 className="text-2xl font-semibold text-white">
                          Create Request
                        </h2>
                        <button
                          onClick={() => setIsPostModalOpen(false)}
                          className="text-gray-400 hover:text-white transition-colors"
                        >
                          <X size={24} />
                        </button>
                      </div>
                      <PostRequestForm
                        onSubmit={handlePostRequest}
                        onClose={() => setIsPostModalOpen(false)}
                        userLocation={userLocation}
                        timeOptions={[
                          { value: '0.083', label: '5 minutes' },
                          { value: '0.5', label: '30 minutes' },
                          { value: '1', label: '1 hour' },
                          { value: '3', label: '3 hours' },
                          { value: '6', label: '6 hours' },
                          { value: '12', label: '12 hours' },
                          { value: '24', label: '24 hours' }
                        ]}
                      />
                    </div>
                  </div>
                </div>
              )}

              {isBusinessFormOpen && (
                <div className="fixed inset-0 bg-black/95 z-[100] flex items-center justify-center">
                  <div
                    className="bg-[#1a1f2e]/90 backdrop-blur-xl rounded-2xl max-w-2xl w-full mx-4 max-h-[90vh] overflow-y-auto
                           border border-white/10 shadow-2xl shadow-black/50 relative"
                  >
                    <div className="p-8">
                      <div className="flex justify-between items-center mb-6">
                        <h2 className="text-2xl font-semibold text-white">
                          Add New Business
                        </h2>
                        <button
                          onClick={() => setIsBusinessFormOpen(false)}
                          className="text-gray-400 hover:text-white transition-colors"
                        >
                          <X size={24} />
                        </button>
                      </div>
                      <BusinessForm
                        onSubmit={handleAddBusiness}
                        onClose={() => setIsBusinessFormOpen(false)}
                        userLocation={userLocation}
                        isMasterWallet={
                          wallet.publicKey?.toString() === MASTER_WALLET
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </LoadScript>
  );
};

export default App;