// NetworksPanel.js

import React from 'react';

const NetworksPanel = () => {
  return (
    <div className="p-4 text-white">
      <button className="mb-4 bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded">
        Launch a Network
      </button>
      <h2 className="font-bold mb-4">Available Networks</h2>
      <div className="bg-[#0f131f] p-3 rounded mb-2">
        <h3 className="font-bold">Food</h3>
        <p className="text-sm text-white/70">Description of Food Network</p>
      </div>
      <div className="bg-[#0f131f] p-3 rounded">
        <h3 className="font-bold">Delivery</h3>
        <p className="text-sm text-white/70">Description of Delivery Network</p>
      </div>
    </div>
  );
};

export default NetworksPanel;
