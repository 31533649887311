import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { db, firebaseManager } from './firebaseConfig';
import { Clock, ArrowUpRight } from 'lucide-react';

const ChainViewComponent = ({ environmentKey }) => {
  const [transitions, setTransitions] = useState([]);
  const [loading, setLoading] = useState(true);

  const getStateTransitionsCollectionName = () => {
    const currentEnv = firebaseManager.getCurrentEnvironment();
    const currentLedger = firebaseManager.getCurrentLedger();
    
    if (currentEnv === 'development') {
      return currentLedger === 'live' ? 'stateTransitions_dev_live' : 'stateTransitions_dev_test';
    }
    return 'stateTransitions_prod_live';
  };

  useEffect(() => {
    setLoading(true);
    const collectionName = getStateTransitionsCollectionName();
    
    const q = query(
      collection(db, collectionName), 
      orderBy('timestamp', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const data = [];
      snapshot.forEach((doc) => {
        const transition = doc.data();
        data.push({
          id: doc.id,
          ...transition,
          timestamp: transition.timestamp?.toDate?.() || new Date(transition.timestamp)
        });
      });
      setTransitions(data);
      setLoading(false);
    }, (error) => {
      console.error('Error fetching state transitions:', error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [environmentKey]);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'Unknown time';
    const now = new Date();
    const diff = now - timestamp;
    const seconds = Math.floor(diff / 1000);
    
    if (seconds < 60) return `${seconds}s ago`;
    if (seconds < 3600) return `${Math.floor(seconds / 60)}m ago`;
    if (seconds < 86400) return `${Math.floor(seconds / 3600)}h ago`;
    return timestamp.toLocaleString();
  };

  const getEventColor = (event) => {
    switch (event) {
      case 'new_request':
        return 'text-green-500';
      case 'new_business':
        return 'text-blue-500';
      default:
        return 'text-white';
    }
  };

  const formatBlockData = (data) => {
    if (!data) return 'No data';
    if (typeof data === 'string') return data;
    
    const formatted = { ...data };
    // Remove verbose or sensitive fields
    delete formatted.createdAt;
    delete formatted.authorId;
    delete formatted.boundary;
    
    return JSON.stringify(formatted, null, 2);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-[#0a0a0a]">
        <div className="text-white text-xl">Loading chain data...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#0a0a0a] text-white p-8">
      <div className="max-w-4xl mx-auto">
        <div className="mb-8">
          <h1 className="text-3xl font-bold">Chain View</h1>
          <p className="text-gray-400 mt-2">
            Viewing {transitions.length} blocks in {firebaseManager.getCurrentEnvironment()} environment
            ({firebaseManager.getCurrentLedger()} chain)
          </p>
        </div>
        
        {transitions.length === 0 ? (
          <div className="bg-[#1a1f2e] rounded-xl p-8 text-center">
            <p className="text-xl mb-2">No state transitions found in the current environment.</p>
            <p className="text-gray-400">Add some requests or businesses to generate blocks.</p>
          </div>
        ) : (
          <div className="space-y-6">
            {transitions.map((block, index) => (
              <div 
                key={block.id} 
                className="bg-[#1a1f2e] rounded-xl p-6 border border-white/10 hover:border-green-500/50 transition-colors"
              >
                <div className="flex items-start justify-between mb-4">
                  <div>
                    <div className={`text-sm font-medium mb-1 ${getEventColor(block.event)}`}>
                      {block.event?.toUpperCase()?.replace('_', ' ')}
                    </div>
                    <div className="flex items-center gap-2 text-gray-400 text-sm">
                      <Clock size={14} />
                      {formatTimestamp(block.timestamp)}
                    </div>
                  </div>
                  <div className="text-right">
                    <div className="text-xs text-gray-400 mb-1">Block {transitions.length - index}</div>
                    <div className="font-mono text-sm text-orange-400">{block.hash?.slice(0, 10)}...</div>
                  </div>
                </div>

                <div className="bg-black/30 rounded-lg p-4">
                  <pre className="text-sm text-gray-300 overflow-x-auto whitespace-pre-wrap">
                    {formatBlockData(block.data)}
                  </pre>
                </div>

                {block.previousHash && (
                  <div className="mt-4 pt-4 border-t border-white/10">
                    <div className="flex items-center gap-2 text-gray-400 text-sm">
                      <ArrowUpRight size={14} />
                      Previous: {block.previousHash?.slice(0, 10)}...
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChainViewComponent;