import React, { useState, useEffect, useRef } from 'react';
import { Clock, MapPin, Globe } from 'lucide-react';

const RequestSidebar = ({
  requests = [],
  timeAgo,
  selectedRequest,
  onSelectRequest,
  userLocation,
  searchTerm = '',
  filterAvailableOnly = false,
  filterGlobal = false,
  filterLocal = false,
  filterMine = false,
  userId = '',
  minReward = '',
  maxReward = ''
}) => {
  const [timeRemaining, setTimeRemaining] = useState({});
  const itemRefs = useRef({});

  useEffect(() => {
    if (selectedRequest && itemRefs.current[selectedRequest.id]) {
      itemRefs.current[selectedRequest.id].scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [selectedRequest]);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = Date.now();
      const updated = {};
      requests.forEach((req) => {
        if (!req.createdAt || !req.timeLimit) {
          updated[req.id] = 99999999999;
          return;
        }
        try {
          const hours = parseFloat(req.timeLimit);
          if (isNaN(hours)) {
            updated[req.id] = 99999999999;
            return;
          }
          const msDuration = hours * 60 * 60 * 1000;
          const createdAtMs = req.createdAt?.toDate
            ? req.createdAt.toDate().getTime()
            : new Date(req.createdAt).getTime();
          const endTime = createdAtMs + msDuration;
          const diff = endTime - now;
          updated[req.id] = diff;
        } catch {
          updated[req.id] = 99999999999;
        }
      });
      setTimeRemaining(updated);
    }, 1000);
    return () => clearInterval(timer);
  }, [requests]);

  const formatTime = (ms) => {
    if (ms <= 0) return 'Ended';
    const totalSec = Math.floor(ms / 1000);
    const h = Math.floor(totalSec / 3600);
    const m = Math.floor((totalSec % 3600) / 60);
    const s = totalSec % 60;
    if (h > 0) return `${h}h ${m}m ${s}s`;
    if (m > 0) return `${m}m ${s}s`;
    return `${s}s`;
  };

  // Helper to check if user is in boundary (returns bool)
  const isWithinBoundary = (req) => {
    if (!userLocation) return false;
    // If it's your own request, skip. (Matches original logic.)
    if (req.authorId === userId) return false;

    try {
      if (req.boundaryType === 'global') return true;

      if (req.boundaryType === 'radius' && req.boundary?.center) {
        const dist = window.google.maps.geometry.spherical.computeDistanceBetween(
          new window.google.maps.LatLng(userLocation.lat, userLocation.lng),
          new window.google.maps.LatLng(
            req.boundary.center.lat,
            req.boundary.center.lng
          )
        );
        const radM = (req.boundary.radius || 5) * 1000;
        return dist <= radM;
      }

      if (req.boundaryType === 'custom' && req.boundary?.points?.length) {
        const userLatLng = new window.google.maps.LatLng(
          userLocation.lat,
          userLocation.lng
        );
        const polygon = new window.google.maps.Polygon({
          paths: req.boundary.points
        });
        return window.google.maps.geometry.poly.containsLocation(
          userLatLng,
          polygon
        );
      }
    } catch (err) {
      console.error('Boundary check error:', err);
    }
    return false;
  };

  // Filtering logic
  let filteredRequests = requests.filter((r) =>
    r.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (minReward !== '') {
    filteredRequests = filteredRequests.filter(
      (r) => parseFloat(r.reward || 0) >= parseFloat(minReward)
    );
  }
  if (maxReward !== '') {
    filteredRequests = filteredRequests.filter(
      (r) => parseFloat(r.reward || 0) <= parseFloat(maxReward)
    );
  }
  if (filterMine) {
    filteredRequests = filteredRequests.filter((r) => r.authorId === userId);
  }
  if (filterGlobal) {
    filteredRequests = filteredRequests.filter((r) => r.boundaryType === 'global');
  }
  if (filterLocal) {
    filteredRequests = filteredRequests.filter(
      (r) => r.boundaryType !== 'global' && isWithinBoundary(r)
    );
  }
  if (filterAvailableOnly) {
    filteredRequests = filteredRequests.filter((r) => isWithinBoundary(r));
  }

  return (
    <div className="w-full h-full bg-gray-900 rounded-xl">
      <div className="h-full overflow-y-auto">
        {filteredRequests.length > 0 ? (
          filteredRequests.map((r) => {
            const msLeft = timeRemaining[r.id] || 99999999999;
            const ended = msLeft <= 0;
            const timeText = ended ? 'Ended' : formatTime(msLeft);

            const usedRes = Array.isArray(r.responses)
              ? r.responses.length
              : r.responseCount || 0;
            const maxRes = r.maxNumberOfResponses || 1;
            const left = Math.max(0, maxRes - usedRes);

            const cpr = parseFloat(r.costPerResponse || 0);
            const cprw = (cpr * 1.1).toFixed(2);

            const subTypes = r.submissionTypes || [];
            const subLabel = subTypes.length ? subTypes.join(', ') : 'TEXT';

            // In-range check:
            const inRange = isWithinBoundary(r);

            return (
              <div
                key={r.id}
                ref={(el) => (itemRefs.current[r.id] = el)}
                onClick={() => onSelectRequest(r)}
                className={`p-4 border-b border-gray-800 cursor-pointer transition-colors duration-200 hover:bg-gray-800/50 ${
                  selectedRequest?.id === r.id ? 'bg-gray-800/50' : ''
                }`}
              >
                <div className="flex justify-between items-center mb-3">
                  <div className="flex items-center gap-3">
                    <span className="text-emerald-400 font-semibold">
                      ${r.reward}
                    </span>
                    <span className="text-gray-400 text-sm">
                      {timeAgo(r.createdAt)}
                    </span>
                  </div>
                  <div className="bg-emerald-900/30 px-2.5 py-1 rounded-full text-xs font-medium text-emerald-400">
                    {subLabel}
                  </div>
                </div>

                <h3 className="text-gray-100 font-medium mb-2 line-clamp-2">
                  {r.authorId === userId && (
                    <span className="text-xs text-blue-400 mr-2">
                      (Your request)
                    </span>
                  )}
                  {r.question}
                </h3>

                <div className="text-gray-300 text-sm mb-3 space-x-2">
                  <span>
                    ${(parseFloat(r.reward || 0) / maxRes).toFixed(2)} per response
                  </span>
                  {cpr > 0 && <span className="text-gray-500">+ ${cprw} fee</span>}
                  <span className="text-gray-500">·</span>
                  <span>{left} slots left</span>
                </div>

                <div className="flex items-center justify-between text-sm text-gray-400">
                  <div className="flex items-center gap-1.5">
                    <Clock className="w-4 h-4" />
                    <span>{timeText}</span>
                  </div>

                  {r.boundaryType === 'global' ? (
                    <div className="flex items-center gap-1.5">
                      <Globe className="w-4 h-4" />
                      <span className="text-blue-400">Global</span>
                    </div>
                  ) : (
                    <div className="flex items-center gap-1.5">
                      <MapPin className="w-4 h-4" />
                      <span className="text-emerald-400">
                        {ended ? 'Ended' : 'Local'}
                      </span>
                      {!inRange && (
                        <span className="text-red-400 ml-2">Out of Range</span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="p-6 text-center text-gray-400">
            No requests found
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestSidebar;