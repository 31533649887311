import React, { useState, useEffect } from 'react';
import {
  Search,
  Menu,
  Coins,
  Store,
  X,
  Plus,
  Minus,
} from 'lucide-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import RequestSidebar from './RequestSidebar';
import DetailsPanel from './DetailsPanel';
import BusinessDetailsPanel from './BusinessDetailsPanel';
import NetworksPanel from './NetworksPanel';
import ChainViewComponent from './ChainViewComponent';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { db, firebaseManager } from './firebaseConfig';

const MASTER_WALLET = 'Ai5oNL8qvQGE2dbYjzXahYvT44KoQ73zrUwsoPNr6PNB';
const TOTAL_SUPPLY = 21000000;
const TOKEN_PRICE_IN_SOL = 0.1;
const SOL_TO_USD = 24.5;

const SidebarContainer = ({
  activeTab,
  setActiveTab,
  searchTerm,
  setSearchTerm,
  requests,
  businesses,
  timeAgo,
  selectedRequest,
  selectedBusiness,
  handleItemSelect,
  transactions,
  userLocation,
  userId,
  localLensBalance,
  onBuyTokens,
  purchaseHistory,
  onAddBusiness,
  onUpdateLocation,
  networkBarHeight,
  isUpdatingLocation,
  setIsUpdatingLocation,
  handleSetNewLocation,
}) => {
  const { connection } = useConnection();
  const wallet = useWallet();
  const [showTransactions, setShowTransactions] = useState(false);
  const [showTokenControls, setShowTokenControls] = useState(false);
  const [showAgentPanel, setShowAgentPanel] = useState(false);
  const [tokenAmount, setTokenAmount] = useState('100');
  const [priceHistory, setPriceHistory] = useState([]);
  const [currentPrice, setCurrentPrice] = useState(0.1);
  const [isLoading, setIsLoading] = useState(false);
  const [showInDollars, setShowInDollars] = useState(true);
  const [timeScale, setTimeScale] = useState('live');
  const [chartType, setChartType] = useState('price');
  const [selectedChainTab, setSelectedChainTab] = useState('requests');
  const [isRequestsCollapsed, setIsRequestsCollapsed] = useState(false);

  // Filters
  const [filterAvailableOnly, setFilterAvailableOnly] = useState(false);
  const [filterGlobal, setFilterGlobal] = useState(false);
  const [filterLocal, setFilterLocal] = useState(false);
  const [filterMine, setFilterMine] = useState(false);
  const [minReward, setMinReward] = useState('');
  const [maxReward, setMaxReward] = useState('');
  const [totalSpent, setTotalSpent] = useState(0);
  const [totalEarned, setTotalEarned] = useState(0);

  // Effects from original code remain the same...
  useEffect(() => {
    if (!userId) return;
    const currentEnv = firebaseManager.getCurrentEnvironment();
    const currentLedger = firebaseManager.getCurrentLedger();
    let requestsColl = 'requests';
    if (currentEnv === 'development') {
      requestsColl =
        currentLedger === 'live' ? 'requests_dev_live' : 'requests_dev_test';
    } else {
      requestsColl = 'requests_prod_live';
    }

    const spent = requests
      .filter((r) => r.authorId === userId)
      .reduce((sum, r) => sum + (parseFloat(r.reward) || 0), 0);
    setTotalSpent(spent);
    setTotalEarned(0);
  }, [requests, userId]);

  useEffect(() => {
    const generatePriceHistory = () => {
      const basePrice = TOKEN_PRICE_IN_SOL * SOL_TO_USD;
      const history = [];
      const points = timeScale === 'live' ? 24 : timeScale === '1d' ? 24 : timeScale === '1w' ? 7 : 30;
      
      for (let i = 0; i < points; i++) {
        const variance = (Math.random() - 0.5) * 0.02 * basePrice;
        const price = basePrice + variance;
        history.push({
          time: i,
          price: price,
          marketcap: price * TOTAL_SUPPLY
        });
      }
      return history;
    };
    setPriceHistory(generatePriceHistory());
  }, [timeScale]);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName.toLowerCase());
  };

  return (
    <>
      {/* Transactions Panel */}
      {showTransactions && (
        <div className="fixed inset-y-0 right-0 w-[600px] bg-gray-900 border-l border-gray-800 shadow-xl z-50 overflow-hidden rounded-l-xl">
          <div className="h-full flex flex-col">
            <div className="p-4 border-b border-gray-800 flex items-center justify-between">
              <div className="flex gap-4">
                {['requests', 'information'].map((tab) => (
                  <button
                    key={tab}
                    onClick={() => setSelectedChainTab(tab)}
                    className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                      selectedChainTab === tab
                        ? 'bg-emerald-500 text-white'
                        : 'text-gray-400 hover:text-white'
                    }`}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </button>
                ))}
              </div>
              <button
                onClick={() => setShowTransactions(false)}
                className="p-2 text-gray-400 hover:text-white hover:bg-gray-800 rounded-lg transition-colors"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <div className="flex-1 overflow-y-auto">
              <ChainViewComponent environmentKey={selectedChainTab} />
            </div>
          </div>
        </div>
      )}

      {/* Top Navigation */}
      <div
        className="absolute top-0 right-0 left-0 transition-all duration-300 z-30"
        style={{ marginTop: networkBarHeight + 10 }}
      >
        <div className="backdrop-blur-sm">
          <div className="px-4 pt-2 flex items-center gap-4">
            <button
              className="p-2 rounded-lg bg-gray-900 text-gray-400 hover:text-white transition-colors"
              onClick={() => setShowTransactions(!showTransactions)}
            >
              <Menu className="w-6 h-6" />
            </button>

            <div className="flex-1 flex items-center justify-between gap-4">
              {/* Search Bar */}
              <div className="relative flex-1">
                <input
                  type="text"
                  placeholder={`Search ${activeTab}...`}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full h-12 bg-gray-900 text-white rounded-lg pl-12 pr-4 
                           border border-gray-800 focus:border-emerald-500/50 focus:outline-none"
                />
                <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
              </div>

              {/* Token Controls */}
              <div className="z-40 flex items-center gap-4">
                <div
                  className="relative"
                  onMouseEnter={() => setShowTokenControls(true)}
                  onMouseLeave={() => !isLoading && setShowTokenControls(false)}
                >
                  <div className="flex items-center gap-4 bg-gray-900 px-4 py-2 rounded-lg cursor-pointer">
                    <div
                      className="flex items-center gap-2"
                      onClick={() => setShowInDollars(!showInDollars)}
                    >
                      <Coins className="w-5 h-5 text-yellow-500" />
                      <span className="text-white font-medium">
                        {showInDollars
                          ? `$${(localLensBalance * TOKEN_PRICE_IN_SOL * SOL_TO_USD).toFixed(2)}`
                          : `${localLensBalance} LL`}
                      </span>
                    </div>
                  </div>

                  {showTokenControls && (
                    <div className="absolute top-full mt-2 right-0 bg-gray-900 rounded-lg border border-gray-800 shadow-xl p-4 w-96">
                      <div className="flex gap-2 mb-4">
                        <input
                          type="number"
                          value={tokenAmount}
                          onChange={(e) => setTokenAmount(e.target.value)}
                          className="flex-1 bg-gray-800 border border-gray-700 rounded px-3 py-1.5 text-white"
                          placeholder="Amount"
                        />
                        <div className="text-gray-400 flex items-center">
                          <span className="text-sm">
                            ≈ ${(Number(tokenAmount) * TOKEN_PRICE_IN_SOL * SOL_TO_USD).toFixed(2)}
                          </span>
                        </div>
                      </div>

                      <div className="flex gap-2">
                        <button
                          onClick={() => onBuyTokens(Number(tokenAmount), TOKEN_PRICE_IN_SOL)}
                          disabled={isLoading || !userLocation}
                          className="flex-1 bg-emerald-500 hover:bg-emerald-600 text-white rounded py-2 font-medium transition-colors
                                   disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          {!userLocation ? 'Enable Location' : isLoading ? 'Processing...' : 'Buy'}
                        </button>
                        <button
                          onClick={() => alert('Selling not implemented yet')}
                          disabled={isLoading || localLensBalance < Number(tokenAmount) || !userLocation}
                          className="flex-1 bg-red-500 hover:bg-red-600 text-white rounded py-2 font-medium transition-colors
                                   disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                          {isLoading ? 'Processing...' : 'Sell'}
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                {/* Profile Menu */}
                <div
                  className="relative"
                  onMouseEnter={() => setShowAgentPanel(true)}
                  onMouseLeave={() => setShowAgentPanel(false)}
                >
                  <button className="px-4 py-2 bg-gray-900 rounded-lg text-white hover:bg-gray-800 transition-colors">
                    Profile
                  </button>

                  {showAgentPanel && (
                    <div className="absolute top-full mt-2 right-0 bg-gray-900 rounded-lg border border-gray-800 shadow-xl p-4 w-72">
                      {/* Location Section */}
                      <div className="mb-4">
                        <h3 className="text-white font-medium mb-2">Location</h3>
                        {userLocation ? (
                          <>
                            <p className="text-gray-400 text-sm mb-2">
                              Lat: {userLocation.lat.toFixed(4)}, Lng: {userLocation.lng.toFixed(4)}
                            </p>
                            {!isUpdatingLocation ? (
                              <button
                                className="px-3 py-2 bg-emerald-500 text-white rounded hover:bg-emerald-600 transition-colors"
                                onClick={() => setIsUpdatingLocation(true)}
                              >
                                Update Location
                              </button>
                            ) : (
                              <button
                                className="px-3 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
                                onClick={handleSetNewLocation}
                              >
                                Set New Location
                              </button>
                            )}
                          </>
                        ) : (
                          <p className="text-gray-400 text-sm">Location not shared</p>
                        )}
                      </div>

                      {/* Your Requests Section */}
                      <div className="mb-4">
                        <h3 className="text-white font-medium mb-2">Your Requests</h3>
                        <div className="max-h-32 overflow-y-auto">
                          {requests?.filter((r) => r.authorId === userId).length ? (
                            requests
                              .filter((r) => r.authorId === userId)
                              .map((request) => (
                                <div key={request.id} className="mb-2 text-sm">
                                  <div className="text-gray-200">{request.question}</div>
                                  <div className="text-emerald-400">${request.reward || '0.00'}</div>
                                </div>
                              ))
                          ) : (
                            <p className="text-gray-400 text-sm">No requests made yet</p>
                          )}
                        </div>
                      </div>

                      {/* Earnings Overview */}
                      <div className="border-t border-gray-800 pt-4">
                        <h3 className="text-white font-medium mb-2">Earnings Overview</h3>
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <p className="text-sm text-gray-400">Total Earned</p>
                            <p className="text-emerald-400 font-medium">${totalEarned.toFixed(2)}</p>
                          </div>
                          <div>
                            <p className="text-sm text-gray-400">Total Spent</p>
                            <p className="text-white font-medium">${totalSpent.toFixed(2)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <WalletMultiButton className="!bg-gray-900 hover:!bg-gray-800" />
              </div>
            </div>
          </div>

          {/* Navigation Tabs */}
          <div className="px-4 pt-2 pb-2 flex items-center gap-2 overflow-x-auto">
            {['Requests', 'Information', 'People', 'Businesses', 'Networks'].map((tab) => (
              <button
                key={tab.toLowerCase()}
                onClick={() => handleTabClick(tab)}
                className={`px-4 py-2 rounded-full whitespace-nowrap transition-colors ${
                  activeTab === tab.toLowerCase()
                    ? 'bg-emerald-500 text-white'
                    : 'bg-gray-900 text-gray-400 hover:text-white'
                  }`}
                  >
                    {tab}
                  </button>
                ))}
              </div>
            </div>
          </div>
    
          {/* Main Content Area */}
          <div
            className="absolute left-4 flex transition-all duration-300 z-20 mt-4"
            style={{
              top: networkBarHeight + 110,
              bottom: '20px',
            }}
          >
            <div className="flex gap-4 h-[calc(100vh-200px)] overflow-y-auto pb-4 pr-4">
              {/* Businesses Tab */}
              {activeTab === 'businesses' && (
                <div className="w-[400px] bg-gray-900 rounded-lg overflow-hidden">
                  <div className="max-h-full overflow-y-auto">
                    {businesses.map((business) => (
                      <div
                        key={business.id}
                        onClick={() => handleItemSelect(business, 'business')}
                        className={`p-4 border-b border-gray-800 cursor-pointer transition-colors hover:bg-gray-800/50 ${
                          selectedBusiness?.id === business.id ? 'bg-gray-800/50' : ''
                        }`}
                      >
                        <div className="flex justify-between items-start mb-2">
                          <div>
                            <h3 className="text-white font-medium">{business.name}</h3>
                            <p className="text-sm text-gray-400">
                              {business.address}
                            </p>
                          </div>
                          <span className="text-gray-400 text-sm">
                            {timeAgo(business.createdAt)}
                          </span>
                        </div>
    
                        <p className="text-gray-300 mb-4 line-clamp-2">
                          {business.description}
                        </p>
    
                        <div className="flex items-center text-sm text-gray-400">
                          <Store className="w-4 h-4 mr-1" />
                          <span>{business.category}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
    
              {/* Requests Tab */}
              {activeTab === 'requests' && (
                <div
                  className={`relative bg-gray-900 rounded-lg overflow-hidden transition-all duration-300
                    w-[400px]
                    ${isRequestsCollapsed ? 'h-12' : 'h-[calc(100vh-200px)]'}
                  `}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <button
                    onClick={() => setIsRequestsCollapsed(!isRequestsCollapsed)}
                    className="absolute top-2 left-2 p-2 bg-gray-800 
                      rounded-lg border border-gray-700 text-gray-400 hover:text-white hover:bg-gray-700 transition-colors"
                    style={{ zIndex: 10 }}
                  >
                    {isRequestsCollapsed ? <Plus className="w-4 h-4" /> : <Minus className="w-4 h-4" />}
                  </button>
    
                  {!isRequestsCollapsed && (
                    <>
                      {/* Filter Controls */}
                      <div className="p-4 pl-16 flex items-center gap-2 border-b border-gray-800">
                        {[
                          { label: 'Available to You', state: filterAvailableOnly, setState: setFilterAvailableOnly },
                          { label: 'Global', state: filterGlobal, setState: setFilterGlobal },
                          { label: 'Local', state: filterLocal, setState: setFilterLocal },
                          { label: 'My Requests', state: filterMine, setState: setFilterMine }
                        ].map(({ label, state, setState }) => (
                          <label key={label} className="flex items-center gap-1.5 text-gray-300">
                            <input
                              type="checkbox"
                              checked={state}
                              onChange={(e) => setState(e.target.checked)}
                              className="rounded border-gray-600 text-emerald-500 focus:ring-emerald-500"
                            />
                            <span className="text-sm">{label}</span>
                          </label>
                        ))}
                      </div>
    
                      {/* Reward Range Inputs */}
                      <div className="px-4 py-2 flex gap-2 border-b border-gray-800">
                        <input
                          type="number"
                          placeholder="Min $"
                          value={minReward}
                          onChange={(e) => setMinReward(e.target.value)}
                          className="w-[70px] bg-gray-800 border border-gray-700 rounded px-2 py-1 text-white placeholder-gray-500"
                        />
                        <input
                          type="number"
                          placeholder="Max $"
                          value={maxReward}
                          onChange={(e) => setMaxReward(e.target.value)}
                          className="w-[70px] bg-gray-800 border border-gray-700 rounded px-2 py-1 text-white placeholder-gray-500"
                        />
                      </div>
    
                      {/* Requests List */}
                      <div className="flex-1 pt-4 overflow-y-auto">
                        <RequestSidebar
                          requests={requests}
                          timeAgo={timeAgo}
                          selectedRequest={selectedRequest}
                          onSelectRequest={(r) => handleItemSelect(r, 'request')}
                          userLocation={userLocation}
                          searchTerm={searchTerm}
                          filterAvailableOnly={filterAvailableOnly}
                          filterGlobal={filterGlobal}
                          filterLocal={filterLocal}
                          filterMine={filterMine}
                          userId={userId}
                          minReward={minReward}
                          maxReward={maxReward}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
    
              {/* Information Tab */}
              {activeTab === 'information' && (
                <div className="w-[400px] bg-gray-900 rounded-lg overflow-hidden p-6">
                  <button
                    onClick={() => alert('Post to Earn functionality coming soon')}
                    className="mb-4 bg-emerald-500 hover:bg-emerald-600 text-white font-medium py-2 px-4 rounded transition-colors"
                  >
                    Post to Earn
                  </button>
                  <div className="text-gray-300">
                    Some informational content...
                  </div>
                </div>
              )}
    
              {/* People Tab */}
              {activeTab === 'people' && (
                <div className="w-[400px] bg-gray-900 rounded-lg overflow-hidden p-6">
                  <button
                    onClick={() => alert('Create Identity coming soon')}
                    className="mb-4 bg-emerald-500 hover:bg-emerald-600 text-white font-medium py-2 px-4 rounded transition-colors"
                  >
                    Create Identity
                  </button>
                  <div className="text-gray-300">
                    List of people or identities...
                  </div>
                </div>
              )}
    
              {/* Networks Tab */}
              {activeTab === 'networks' && (
                <div className="w-[400px] bg-gray-900 rounded-lg overflow-hidden p-6">
                  <NetworksPanel />
                </div>
              )}
    
              {/* Business Details Panel */}
              {selectedBusiness && (
                <BusinessDetailsPanel
                  business={selectedBusiness}
                  onClose={() => handleItemSelect(null, null)}
                  userLocation={userLocation}
                />
              )}
    
              {/* Request Details Panel */}
              {selectedRequest && (
                <DetailsPanel
                  request={selectedRequest}
                  onClose={() => handleItemSelect(null, null)}
                  userId={userId}
                  userLocation={userLocation}
                />
              )}
            </div>
          </div>
        </>
      );
    };
    
    export default SidebarContainer;