import React, { useRef, useState, useCallback, useEffect } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { Search } from 'lucide-react';

const LocationSearch = ({
  formState,
  handleInputChange,
  userLocation,
  setLocationError,
  handleLocationSelect
}) => {
  const searchInputRef = useRef(null);
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  
  // Clear and create markers when position changes
  useEffect(() => {
    if (!map) return;

    // Clear existing markers
    markers.forEach(marker => marker.setMap(null));
    setMarkers([]);

    const position = formState.targetLocation || (formState.location === 'Current Location' ? userLocation : null);
    if (!position) return;

    // Create pin marker
    const pinMarker = new window.google.maps.Marker({
      position,
      map,
      icon: {
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 10,
        fillColor: '#f97316',
        fillOpacity: 1,
        strokeColor: '#ffffff',
        strokeWeight: 2
      },
      zIndex: 2
    });

    // Create radius circle
    const circle = new window.google.maps.Circle({
      center: position,
      radius: parseFloat(formState.radius || '5') * 1609.34,
      map,
      fillColor: '#f97316',
      fillOpacity: 0.15,
      strokeColor: '#f97316',
      strokeOpacity: 1,
      strokeWeight: 3,
      zIndex: 1
    });

    setMarkers([pinMarker, circle]);
  }, [map, formState.targetLocation, formState.location, formState.radius, userLocation]);

  // Initialize map
  const onMapLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
    const location = formState.targetLocation || userLocation;
    if (location) {
      mapInstance.panTo(location);
      mapInstance.setZoom(13);
    }

    // Set up autocomplete
    const autocomplete = new window.google.maps.places.Autocomplete(searchInputRef.current, {
      fields: ['geometry', 'formatted_address']
    });

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        setLocationError('Please select a location from the dropdown');
        return;
      }

      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };

      handleLocationSelect({
        place_name: place.formatted_address,
        coordinates: [location.lng, location.lat]
      });
    });
  }, []);

  const handleMapClick = useCallback((event) => {
    if (!event.latLng) return;
    
    const clickedLocation = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    };

    handleLocationSelect({
      place_name: 'Custom Location',
      coordinates: [clickedLocation.lng, clickedLocation.lat]
    });
  }, [handleLocationSelect]);

  const center = formState.targetLocation || userLocation || { lat: 42.3601, lng: -71.0589 };

  return (
    <div className="space-y-4">
      <div className="relative">
        <input
          ref={searchInputRef}
          type="text"
          value={formState.location}
          onChange={(e) => handleInputChange('location', e.target.value)}
          placeholder="Search for a location or click on the map"
          className="w-full bg-[#1f2637] rounded-lg p-4 pl-12 text-white 
                    border border-[#2a3142] focus:outline-none focus:border-orange-500"
        />
        <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
      </div>

      <div className="h-64 rounded-lg overflow-hidden">
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={center}
          zoom={13}
          onLoad={onMapLoad}
          onClick={handleMapClick}
          options={{
            mapTypeId: 'satellite',
            disableDefaultUI: false,
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: true,
            mapTypeControlOptions: {
              mapTypeIds: ['satellite', 'terrain'],
              style: 2
            }
          }}
        />
      </div>

      {/* Debug info */}
      <div className="text-xs text-gray-400">
        Location: {formState.location || 'None'}<br />
        Has marker: {markers.length > 0 ? 'Yes' : 'No'}<br />
        Coords: {formState.targetLocation ? 
          `${formState.targetLocation.lat.toFixed(4)}, ${formState.targetLocation.lng.toFixed(4)}` : 'None'}
      </div>
    </div>
  );
};

export default LocationSearch;