import React, { useState, useEffect } from 'react';
import { DollarSign } from 'lucide-react';
import ScopeSection from './ScopeSection';

const PostRequestForm = ({
  onSubmit,
  onClose,
  userLocation,
  timeOptions = [
    { value: '0.083', label: '5 minutes' },
    { value: '0.5', label: '30 minutes' },
    { value: '1', label: '1 hour' },
    { value: '3', label: '3 hours' },
    { value: '6', label: '6 hours' },
    { value: '12', label: '12 hours' },
    { value: '24', label: '24 hours' }
  ],
  initialValues = {},
  isEdit = false // if true, we are updating an existing request
}) => {
  const [formState, setFormState] = useState({
    question: initialValues.question || '',
    reward: initialValues.reward || '',
    timeLimit: initialValues.timeLimit || '1',
    scope: initialValues.scope || 'local',
    location: 'Current Location',
    radius: initialValues.radius || '5',
    boundaryType: 'radius',
    customBounds: initialValues.customBounds || null,
    isAnonymous: initialValues.isAnonymous || false,
    targetLocation: userLocation
      ? {
          lat: userLocation.lat,
          lng: userLocation.lng
        }
      : null,
    maxNumberOfResponses: initialValues.maxNumberOfResponses || 1,
    // Renamed: used to be costPerResponse -> now averageEarningPerResponse
    averageEarningPerResponse: initialValues.averageEarningPerResponse || '',
    showResponses: initialValues.showResponses ?? false,
    submissionTypes: initialValues.submissionTypes || []
  });

  const [locationError, setLocationError] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(
    userLocation
      ? {
          place_name: 'Current Location',
          coordinates: [userLocation.lng, userLocation.lat]
        }
      : null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (field, value) => {
    setFormState((prev) => {
      let newState = { ...prev, [field]: value };

      if (field === 'scope') {
        if (value === 'local') {
          if (userLocation) {
            setSelectedLocation({
              place_name: 'Current Location',
              coordinates: [userLocation.lng, userLocation.lat]
            });
            newState.location = 'Current Location';
            newState.targetLocation = {
              lat: userLocation.lat,
              lng: userLocation.lng
            };
          }
        } else {
          newState.location = '';
          newState.customBounds = null;
          newState.targetLocation = null;
          setSelectedLocation(null);
        }
        setLocationError('');
      }

      if (field === 'boundaryType') {
        if (value === 'radius') {
          if (prev.location && prev.targetLocation) {
            // keep existing
          } else if (userLocation) {
            newState.targetLocation = {
              lat: userLocation.lat,
              lng: userLocation.lng
            };
            newState.location = 'Current Location';
          } else {
            newState.targetLocation = null;
            newState.location = '';
          }
        } else {
          newState.targetLocation = null;
          newState.customBounds = null;
        }
      }

      return newState;
    });
  };

  const handleRegionChange = (region) => {
    if (region) {
      setFormState((prev) => ({
        ...prev,
        boundaryType: 'custom',
        customBounds: {
          points: region.points,
          center: region.center,
          area: region.area
        },
        targetLocation: region.center
      }));
    }
  };

  const handleLocationSelect = (suggestion) => {
    setSelectedLocation(suggestion);
    setFormState((prev) => ({
      ...prev,
      location: suggestion.place_name,
      targetLocation: {
        lat: suggestion.coordinates[1],
        lng: suggestion.coordinates[0]
      }
    }));
    setLocationError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const timeLimit = Number(formState.timeLimit);
      const reward = Number(formState.reward);
      const maxNumberOfResponses = Number(formState.maxNumberOfResponses);
      let averageEarningPerResponse = parseFloat(formState.averageEarningPerResponse || 0);

      if (isNaN(timeLimit) || timeLimit <= 0) {
        throw new Error('Invalid time limit');
      }
      if (isNaN(reward) || reward <= 0) {
        throw new Error('Invalid reward');
      }
      if (
        isNaN(maxNumberOfResponses) ||
        maxNumberOfResponses < 1 ||
        !Number.isInteger(maxNumberOfResponses)
      ) {
        throw new Error('Max number of responses must be a positive integer');
      }

      if (isNaN(averageEarningPerResponse) || averageEarningPerResponse < 0) {
        averageEarningPerResponse = 0;
      }

      const submissionData = {
        question: formState.question,
        reward,
        timeLimit,
        createdAt: new Date().toISOString(),
        status: 'active',
        makerLocation: null,
        boundaryType: formState.boundaryType,
        isAnonymous: formState.isAnonymous,
        maxNumberOfResponses,
        averageEarningPerResponse,
        showResponses: formState.showResponses,
        submissionTypes: formState.submissionTypes
      };

      if (formState.scope === 'global') {
        submissionData.boundaryType = 'global';
        submissionData.boundary = { type: 'global' };
        if (userLocation) {
          submissionData.makerLocation = {
            lat: Number(userLocation.lat),
            lng: Number(userLocation.lng)
          };
        } else {
          submissionData.makerLocation = { lat: 20, lng: 0 };
        }
      } else if (formState.scope === 'local') {
        if (formState.boundaryType === 'custom' && formState.customBounds) {
          submissionData.boundary = {
            type: 'custom',
            points: formState.customBounds.points.map((pt) => ({
              lat: Number(pt.lat),
              lng: Number(pt.lng)
            })),
            center: {
              lat: Number(formState.customBounds.center.lat),
              lng: Number(formState.customBounds.center.lng)
            },
            area: Number(formState.customBounds.area)
          };
          submissionData.makerLocation = {
            lat: Number(formState.customBounds.center.lat),
            lng: Number(formState.customBounds.center.lng)
          };
        } else if (formState.boundaryType === 'radius') {
          if (!formState.targetLocation) {
            throw new Error('Please select a location');
          }
          submissionData.boundary = {
            type: 'radius',
            center: {
              lat: Number(formState.targetLocation.lat),
              lng: Number(formState.targetLocation.lng)
            },
            radius: parseInt(formState.radius),
            address: formState.location
          };
          submissionData.makerLocation = {
            lat: Number(formState.targetLocation.lat),
            lng: Number(formState.targetLocation.lng)
          };
        }
      }

      await onSubmit(submissionData, isEdit);
      onClose();
    } catch (error) {
      console.error('Form submission error:', error);
      setLocationError(error.message);
      alert('Failed to save request: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-h-[calc(100vh-120px)] overflow-y-auto pb-6">
      <form onSubmit={handleSubmit} className="flex flex-col space-y-6">
        <div className="space-y-6">
          {/* QUESTION */}
          <div>
            <label className="block text-gray-400 mb-2">
              What do you want to ask?
            </label>
            <textarea
              value={formState.question}
              onChange={(e) => handleInputChange('question', e.target.value)}
              className="w-full min-h-[120px] bg-[#1f2637] rounded-lg p-4 text-white 
                        border border-[#2a3142] focus:outline-none focus:border-orange-500
                        resize-none"
              placeholder="e.g. Where can I find the best coffee?"
              required
            />
          </div>

          {/* REWARD */}
          <div>
            <label className="block text-gray-400 mb-2">Reward Amount</label>
            <div className="relative">
              <div className="absolute left-4 top-1/2 -translate-y-1/2 text-green-400">
                <DollarSign size={20} />
              </div>
              <input
                type="number"
                value={formState.reward}
                onChange={(e) => handleInputChange('reward', e.target.value)}
                className="w-full bg-[#1f2637] rounded-lg p-4 pl-12 text-green-400 font-medium
                         border border-[#2a3142] focus:outline-none focus:border-orange-500"
                placeholder="0.00"
                step="0.01"
                min="0.01"
                required
              />
            </div>
          </div>

          {/* TIME LIMIT */}
          <div>
            <label className="block text-gray-400 mb-2">Time Limit</label>
            <select
              value={formState.timeLimit}
              onChange={(e) => handleInputChange('timeLimit', e.target.value)}
              className="w-full bg-[#1f2637] rounded-lg p-4 text-white 
                       border border-[#2a3142] focus:outline-none focus:border-orange-500 
                       appearance-none cursor-pointer"
            >
              {timeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          {/* ANONYMOUS */}
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={formState.isAnonymous}
              onChange={(e) =>
                setFormState((prev) => ({
                  ...prev,
                  isAnonymous: e.target.checked
                }))
              }
            />
            <span className="text-white text-sm">Post Anonymously</span>
          </div>

          {/* Max # of responses */}
          <div>
            <label className="block text-gray-400 mb-2">
              Max Number of Responses
            </label>
            <input
              type="number"
              value={formState.maxNumberOfResponses}
              onChange={(e) =>
                handleInputChange('maxNumberOfResponses', e.target.value)
              }
              className="w-full bg-[#1f2637] rounded-lg p-4 text-green-400 
                         border border-[#2a3142] focus:outline-none focus:border-orange-500"
              min="1"
              required
            />
          </div>

          {/* Average Earning Per Response */}
          <div>
            <label className="block text-gray-400 mb-2">
              Average Earning Per Response
            </label>
            <input
              type="number"
              value={formState.averageEarningPerResponse}
              onChange={(e) => handleInputChange('averageEarningPerResponse', e.target.value)}
              className="w-full bg-[#1f2637] rounded-lg p-4 text-green-400 
                         border border-[#2a3142] focus:outline-none focus:border-orange-500"
              placeholder="e.g. 0.05"
              step="0.01"
              min="0"
            />
            <p className="text-xs text-white/40 mt-1">
              (Replaces cost per response — approximate share per valid submission.)
            </p>
          </div>

          {/* Show responses? */}
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={formState.showResponses}
              onChange={(e) =>
                setFormState((prev) => ({
                  ...prev,
                  showResponses: e.target.checked
                }))
              }
            />
            <span className="text-white text-sm">
              Make responses visible to everyone
            </span>
          </div>

          {/* Submission Types */}
          <div>
            <label className="block text-gray-400 mb-2">
              Allowed Submission Types
            </label>
            <div className="flex gap-4">
              {['text', 'image', 'audio', 'video'].map((type) => (
                <label key={type} className="flex items-center gap-1 text-white/80">
                  <input
                    type="checkbox"
                    checked={formState.submissionTypes.includes(type)}
                    onChange={() => {
                      setFormState((prev) => {
                        const { submissionTypes } = prev;
                        if (submissionTypes.includes(type)) {
                          return {
                            ...prev,
                            submissionTypes: submissionTypes.filter((t) => t !== type)
                          };
                        } else {
                          return {
                            ...prev,
                            submissionTypes: [...submissionTypes, type]
                          };
                        }
                      });
                    }}
                  />
                  <span className="text-sm capitalize">{type}</span>
                </label>
              ))}
            </div>
          </div>
        </div>

        {/* Scope (Local or Global) */}
        <ScopeSection
          formState={formState}
          handleInputChange={handleInputChange}
          userLocation={userLocation}
          handleRegionChange={handleRegionChange}
          locationError={locationError}
          setLocationError={setLocationError}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          handleLocationSelect={handleLocationSelect}
        />

        {/* Buttons */}
        <div className="flex gap-4 pt-4">
          <button
            type="button"
            onClick={onClose}
            className="flex-1 py-3 rounded-lg text-white hover:bg-[#262d40] transition-colors"
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="flex-1 py-3 rounded-lg bg-orange-500 text-white hover:bg-orange-600 transition-colors
                     disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isSubmitting}
          >
            {isSubmitting
              ? 'Posting...'
              : isEdit
              ? 'Update Request'
              : 'Post Request'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PostRequestForm;