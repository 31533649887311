// MainMap.js
import React, { useState, useCallback, useEffect } from 'react';
import {
  GoogleMap,
  Marker,
  Circle,
  OverlayView,
  Polygon,
  useLoadScript
} from '@react-google-maps/api';
import { MapPin, Eye, EyeOff, Trash2 } from 'lucide-react';

const GOOGLE_MAPS_API_KEY = 'AIzaSyBs6odIntk0EbNgKpMKjLoe6_1ttBlGmbA';
const libraries = ['places', 'geometry', 'drawing'];

const DEFAULT_CENTER = { lat: 20, lng: 0 };
const DEFAULT_ZOOM = 3;

const MapControls = ({
  map,
  userLocation,
  onCenterOnLocation,
  showAvailable,
  setShowAvailable,
  activeTab
}) => {
  if (!map) return null;
  return (
    <div className="absolute right-4 top-1/2 -translate-y-1/2 z-20">
      <div className="bg-white/90 backdrop-blur rounded-lg shadow-lg flex flex-col gap-2 p-2">
        <button
          onClick={onCenterOnLocation}
          disabled={!userLocation}
          className="p-2 hover:bg-gray-100 transition-colors rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
          title="Center on your location"
        >
          <MapPin className={userLocation ? 'text-orange-500' : 'text-gray-400'} />
        </button>
        {activeTab === 'requests' && (
          <button
            onClick={() => setShowAvailable(!showAvailable)}
            className="p-2 hover:bg-gray-100 transition-colors rounded-lg"
            title={showAvailable ? 'Show all requests' : 'Show available only'}
          >
            {showAvailable ? (
              <Eye className="text-green-500" />
            ) : (
              <EyeOff className="text-orange-500" />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

const calculateCenter = (points) => {
  if (!points || points.length === 0) return null;
  let sumLat = 0;
  let sumLng = 0;
  let count = 0;
  points.forEach((p) => {
    const lat = parseFloat(p.lat);
    const lng = parseFloat(p.lng);
    if (!isNaN(lat) && !isNaN(lng)) {
      sumLat += lat;
      sumLng += lng;
      count++;
    }
  });
  if (count === 0) return null;
  return { lat: sumLat / count, lng: sumLng / count };
};

const fitToCustomBoundary = (map, points) => {
  if (!points || points.length === 0) return false;
  const bounds = new window.google.maps.LatLngBounds();
  points.forEach((p) => {
    const lat = parseFloat(p.lat);
    const lng = parseFloat(p.lng);
    if (!isNaN(lat) && !isNaN(lng)) {
      bounds.extend({ lat, lng });
    }
  });
  if (!bounds.isEmpty()) {
    setTimeout(() => {
      map.fitBounds(bounds, { padding: 80 });
      window.google.maps.event.addListenerOnce(map, 'idle', () => {
        if (map.getZoom() < 5) {
          map.setZoom(5);
        }
      });
    }, 50);
    return true;
  }
  return false;
};

const fitToSingleLocation = (map, lat, lng, radius = 1000) => {
  const validLat = parseFloat(lat);
  const validLng = parseFloat(lng);
  if (isNaN(validLat) || isNaN(validLng)) return;

  const latOffset = radius * 0.0001;
  const lngOffset = radius * 0.0001;
  const bounds = new window.google.maps.LatLngBounds();
  bounds.extend({ lat: validLat + latOffset, lng: validLng + lngOffset });
  bounds.extend({ lat: validLat - latOffset, lng: validLng - lngOffset });

  setTimeout(() => {
    map.fitBounds(bounds, { padding: 100 });
    window.google.maps.event.addListenerOnce(map, 'idle', () => {
      if (map.getZoom() < 2) {
        map.setZoom(2);
      } else if (map.getZoom() > 15) {
        // do nothing; let it zoom in
      }
    });
  }, 50);
};

const MainMap = ({
  map,
  setMap,
  userLocation,
  requests,
  businesses,
  activeTab,
  showAvailable,
  setShowAvailable,
  selectedRequest,
  selectedBusiness,
  userId,
  onDeleteRequest,
  handleItemSelect,
  isUpdatingLocation,
  newLocation,
  setNewLocation
}) => {
  const [selectedBoundary, setSelectedBoundary] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries
  });

  const repositionMap = useCallback(() => {
    if (!map) return;
    // If a request is selected
    if (selectedRequest?.id) {
      setSelectedBoundary(selectedRequest.id);

      // Attempt boundary logic
      if (
        selectedRequest.boundaryType === 'custom' &&
        selectedRequest.boundary?.points?.length > 0
      ) {
        if (fitToCustomBoundary(map, selectedRequest.boundary.points)) return;
      }

      let lat = null;
      let lng = null;
      let radius = 1000;

      if (
        selectedRequest.boundaryType === 'radius' &&
        selectedRequest.boundary?.center
      ) {
        lat = parseFloat(selectedRequest.boundary.center.lat);
        lng = parseFloat(selectedRequest.boundary.center.lng);
        radius = (selectedRequest.boundary.radius || 5) * 1000;
      } else if (selectedRequest.boundaryType === 'global') {
        if (
          selectedRequest.makerLocation?.lat &&
          selectedRequest.makerLocation?.lng
        ) {
          lat = parseFloat(selectedRequest.makerLocation.lat);
          lng = parseFloat(selectedRequest.makerLocation.lng);
        } else {
          // fallback
          lat = 20;
          lng = 0;
        }
      } else if (selectedRequest.makerLocation?.lat) {
        lat = parseFloat(selectedRequest.makerLocation.lat);
        lng = parseFloat(selectedRequest.makerLocation.lng);
      }

      // If lat,lng is invalid, fallback to userLocation or default
      if (isNaN(lat) || isNaN(lng)) {
        if (userLocation && !isNaN(userLocation.lat) && !isNaN(userLocation.lng)) {
          fitToSingleLocation(map, userLocation.lat, userLocation.lng, 2000);
        } else {
          map.setCenter(DEFAULT_CENTER);
          map.setZoom(DEFAULT_ZOOM);
        }
        return;
      }

      // OK
      fitToSingleLocation(map, lat, lng, radius);

    } else if (selectedBusiness?.id) {
      // If a business is selected
      if (
        selectedBusiness.location?.lat &&
        selectedBusiness.location?.lng &&
        !isNaN(parseFloat(selectedBusiness.location.lat)) &&
        !isNaN(parseFloat(selectedBusiness.location.lng))
      ) {
        fitToSingleLocation(
          map,
          parseFloat(selectedBusiness.location.lat),
          parseFloat(selectedBusiness.location.lng),
          2000
        );
      }
    } else {
      // fallback if no selection
      map.setCenter(DEFAULT_CENTER);
      map.setZoom(DEFAULT_ZOOM);
      setSelectedBoundary(null);
    }
  }, [map, selectedRequest, selectedBusiness, userLocation]);

  useEffect(() => {
    repositionMap();
  }, [selectedRequest, selectedBusiness, map, repositionMap]);

  const onMapLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
    mapInstance.setMapTypeId('hybrid');
  }, [setMap]);

  const centerOnLocation = useCallback(() => {
    if (map && userLocation) {
      fitToSingleLocation(
        map,
        parseFloat(userLocation.lat),
        parseFloat(userLocation.lng),
        5000
      );
    }
  }, [map, userLocation]);

  if (loadError || !isLoaded) return null;

  return (
    <div className="fixed inset-0 w-screen h-screen">
      <GoogleMap
        mapContainerClassName="w-full h-screen"
        center={DEFAULT_CENTER}
        zoom={DEFAULT_ZOOM}
        onLoad={onMapLoad}
        onClick={() => {
          if (selectedRequest || selectedBusiness) {
            handleItemSelect(null, null);
          }
        }}
        options={{
          mapTypeId: 'hybrid',
          disableDefaultUI: true,
          zoomControl: true,
          fullscreenControl: true,
          zoomControlOptions: {
            position: window.google.maps.ControlPosition.RIGHT_CENTER
          },
          fullscreenControlOptions: {
            position: window.google.maps.ControlPosition.BOTTOM_RIGHT
          },
          gestureHandling: 'greedy',
          minZoom: 2,
          maxZoom: 21,
          restriction: {
            latLngBounds: { north: 85, south: -85, west: -179, east: 179 },
            strictBounds: true
          },
          styles: [
            {
              featureType: 'all',
              elementType: 'labels',
              stylers: [{ visibility: 'off' }]
            },
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [{ color: '#001B44' }]
            }
          ],
          streetViewControl: false,
          backgroundColor: '#000000'
        }}
      >
        {/* user location */}
        {userLocation && !isNaN(userLocation.lat) && !isNaN(userLocation.lng) && (
          <>
            <Marker
              position={{
                lat: parseFloat(userLocation.lat),
                lng: parseFloat(userLocation.lng)
              }}
              icon={{
                path: window.google.maps.SymbolPath.CIRCLE,
                fillColor: '#f97316',
                fillOpacity: 1,
                strokeWeight: 2,
                strokeColor: '#fff',
                scale: 8
              }}
            />
            <OverlayView
              position={{
                lat: parseFloat(userLocation.lat),
                lng: parseFloat(userLocation.lng)
              }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
              <div className="bg-white/90 text-xs text-black px-2 py-1 rounded-lg transform -translate-y-full mb-2 font-semibold">
                You
              </div>
            </OverlayView>
          </>
        )}

        {isUpdatingLocation && (
          <Marker
            position={
              newLocation
                ? { lat: newLocation.lat, lng: newLocation.lng }
                : userLocation
            }
            draggable
            onDragEnd={(e) => {
              const lat = e.latLng.lat();
              const lng = e.latLng.lng();
              setNewLocation({ lat, lng });
            }}
            icon={{
              path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
              fillColor: '#22c55e',
              fillOpacity: 1,
              strokeColor: '#ffffff',
              strokeWeight: 2,
              scale: 6
            }}
          />
        )}

        {/* Businesses */}
        {activeTab === 'businesses' &&
          businesses.map((biz) => {
            if (!biz.location?.lat || !biz.location?.lng) return null;
            const lat = parseFloat(biz.location.lat);
            const lng = parseFloat(biz.location.lng);
            if (isNaN(lat) || isNaN(lng)) return null;
            const isSelected = selectedBusiness?.id === biz.id;
            return (
              <React.Fragment key={biz.id}>
                <Marker
                  position={{ lat, lng }}
                  icon={{
                    path: window.google.maps.SymbolPath.CIRCLE,
                    fillColor: '#22c55e',
                    fillOpacity: 1,
                    strokeColor: '#ffffff',
                    strokeWeight: 2,
                    scale: isSelected ? 10 : 8
                  }}
                  onClick={() => handleItemSelect(biz, 'business')}
                />
                {isSelected && biz.boundary?.points?.length && (
                  <Polygon
                    paths={biz.boundary.points
                      .map((p) => ({
                        lat: parseFloat(p.lat),
                        lng: parseFloat(p.lng)
                      }))
                      .filter((p) => !isNaN(p.lat) && !isNaN(p.lng))}
                    options={{
                      strokeColor: '#22c55e',
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                      fillColor: '#22c55e',
                      fillOpacity: 0.1
                    }}
                  />
                )}
              </React.Fragment>
            );
          })}

        {/* Requests */}
        {activeTab === 'requests' &&
          requests.map((req) => {
            let location = null;
            if (req.boundaryType === 'global') {
              if (req.makerLocation?.lat && req.makerLocation?.lng) {
                location = {
                  lat: parseFloat(req.makerLocation.lat),
                  lng: parseFloat(req.makerLocation.lng)
                };
              } else {
                location = { lat: 20, lng: 0 };
              }
            } else if (
              req.boundaryType === 'custom' &&
              req.boundary?.points?.length > 0
            ) {
              location = calculateCenter(req.boundary.points);
            } else if (
              req.boundaryType === 'radius' &&
              req.boundary?.center
            ) {
              location = {
                lat: parseFloat(req.boundary.center.lat),
                lng: parseFloat(req.boundary.center.lng)
              };
            } else if (req.makerLocation?.lat && req.makerLocation?.lng) {
              location = {
                lat: parseFloat(req.makerLocation.lat),
                lng: parseFloat(req.makerLocation.lng)
              };
            }
            if (!location || isNaN(location.lat) || isNaN(location.lng)) return null;

            const isSelected = selectedBoundary === req.id;
            return (
              <React.Fragment key={req.id}>
                <Marker
                  position={location}
                  icon={{
                    path: window.google.maps.SymbolPath.CIRCLE,
                    fillColor: isSelected ? '#f97316' : '#22c55e',
                    fillOpacity: 1,
                    strokeWeight: 2,
                    strokeColor: '#fff',
                    scale: isSelected ? 10 : 8
                  }}
                  onClick={() => {
                    setSelectedBoundary(req.id);
                    handleItemSelect(req, 'request');
                  }}
                />
                {isSelected && (
                  <>
                    {req.boundaryType === 'custom' && req.boundary?.points?.length ? (
                      <Polygon
                        paths={req.boundary.points
                          .map((p) => ({
                            lat: parseFloat(p.lat),
                            lng: parseFloat(p.lng)
                          }))
                          .filter((p) => !isNaN(p.lat) && !isNaN(p.lng))}
                        options={{
                          strokeColor: '#f97316',
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                          fillColor: '#f97316',
                          fillOpacity: 0.1
                        }}
                      />
                    ) : req.boundaryType === 'radius' ? (
                      <Circle
                        center={location}
                        radius={(req.boundary?.radius || 5) * 1000}
                        options={{
                          strokeColor: '#f97316',
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                          fillColor: '#f97316',
                          fillOpacity: 0.1
                        }}
                      />
                    ) : null}

                    <OverlayView
                      position={location}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    >
                      <div
                        className={`bg-white/90 backdrop-blur shadow-lg px-3 py-1 rounded-lg 
                          transform -translate-y-full mb-2 cursor-pointer
                          ${isSelected ? 'scale-110' : ''} transition-all duration-300`}
                      >
                        <div className="flex items-center gap-2">
                          <div className="text-lg font-bold text-orange-500">
                            ${req.reward}
                          </div>
                          {req.authorId === userId && onDeleteRequest && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                onDeleteRequest(req.id);
                              }}
                              className="p-1 hover:bg-red-100 rounded-full transition-colors"
                            >
                              <Trash2 className="w-4 h-4 text-red-500" />
                            </button>
                          )}
                        </div>
                      </div>
                    </OverlayView>
                  </>
                )}
              </React.Fragment>
            );
          })}
      </GoogleMap>
      <MapControls
        map={map}
        userLocation={userLocation}
        onCenterOnLocation={centerOnLocation}
        showAvailable={showAvailable}
        setShowAvailable={setShowAvailable}
        activeTab={activeTab}
      />
    </div>
  );
};

export default MainMap;